import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import { IDegree } from '../../../../models/application-process.model.js';
import { StyledCard, StyledTable } from '../FormsStyles';
import { degreeOptions } from '../../InstructionsList';

type DegreeListProps = {
  Degrees: IDegree[];
  onEdit: (Degree: IDegree) => void;
  onRemove: (id: string) => void;
};

const DegreeList: React.FC<DegreeListProps> = ({
  Degrees,
  onEdit,
  onRemove,
}) => {
  const getNameDegree = (degreeId: string): string => {
    const degree = degreeOptions.find((option) => option.id === degreeId);
    return degree ? degree.name : '';
  };

  return (
    <>
      <StyledCard>
        {Degrees.map((exp) => (
          <div
            key={exp.id}
            style={{
              margin: '10px',
              borderBottom: '1px solid #ddd',
              paddingBottom: '10px',
            }}
          >
            <div>
              <strong>Curso:</strong> {exp.name}
            </div>
            <div>
              <strong>Nível:</strong> {exp.degreeLevelsId}
            </div>
            <div>
              <strong>Carga Horária:</strong> {exp.workload}
            </div>
            <div>
              <Button
                onClick={() => onEdit(exp)}
                variant="outline-primary"
                size="sm"
                style={{ marginRight: '5px' }}
              >
                <FontAwesomeIcon icon={faEdit} /> Editar
              </Button>
              <Button
                onClick={() => onRemove(exp.id)}
                variant="outline-danger"
                size="sm"
              >
                <FontAwesomeIcon icon={faTrashAlt} /> Remover
              </Button>
            </div>
          </div>
        ))}
      </StyledCard>
      <StyledTable striped bordered hover>
        <thead>
          <tr>
            <th>Curso</th>
            <th>Nível</th>
            <th>Carga Horária</th>
          </tr>
        </thead>
        <tbody>
          {Degrees.map((exp) => (
            <tr key={exp.id}>
              <td>{exp.name}</td>
              <td>{getNameDegree(exp.degreeLevelsId)}</td>
              <td>{exp.workload}</td>
              <td>
                <div>
                  <Button
                    onClick={() => onEdit(exp)}
                    variant="outline-primary"
                    size="sm"
                    style={{ marginRight: '5px' }}
                  >
                    <FontAwesomeIcon icon={faEdit} /> Editar
                  </Button>
                  <Button
                    onClick={() => onRemove(exp.id)}
                    variant="outline-danger"
                    size="sm"
                  >
                    <FontAwesomeIcon icon={faTrashAlt} /> Remover
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </>
  );
};

export default DegreeList;
