import React, { useEffect, useState } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { IUser } from '../models/login.model';
import { formatDateJs } from '../utils/format';

const ProfilePage: React.FC = () => {
  const [user, setUser] = useState<IUser>();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  return (
    <Container
      style={{ maxWidth: '800px', marginTop: '40px', background: '#fcfcfc' }}
    >
      <Row className="justify-content-center">
        {user && (
          <Col xs={12} md={6}>
            <Card>
              <Card.Body>
                <Card.Title>Perfil do Usuário</Card.Title>
                <Card.Text>
                  <strong>Nome:</strong> {user!.name}
                  <br />
                  <strong>Username:</strong> {user!.username}
                  <br />
                  <strong>Email:</strong> {user!.candidate.email}
                  <br />
                  <strong>Telefone:</strong> {user!.candidate.phone}
                  <br />
                  <strong>Data de Nascimento:</strong>{' '}
                  {formatDateJs(user!.candidate.birthdate)}
                  <br />
                  <strong>PCD:</strong> {user!.candidate.pcd ? 'Sim' : 'Não'}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default ProfilePage;
