import React from 'react';
import { Container } from 'react-bootstrap';
import TitleInstructions from '../JobApplication/components/TitleInstructions';
import { ICertification } from '../../models/application-process.model';
import { StyledCardScreen, Table } from './MySubscribeStyle';
import UploadForm from './UploadForm/UploadForm';
import {
  formationCourseInstrutions,
  uploadCourseInstrutions,
} from './instructionListScreen';

interface CertificationTableProps {
  certifications?: ICertification[];
  degree?: boolean;
  setAttachment: (file: File) => void;
  uploadFile?: boolean;
}

const CertificationTable: React.FC<CertificationTableProps> = ({
  certifications,
  degree,
  setAttachment,
  uploadFile = false,
}) => {
  const setUploadedFile = (file: File) => {
    setAttachment(file);
  };

  return (
    <StyledCardScreen>
      <Container>
        {uploadFile ? (
          <TitleInstructions info={uploadCourseInstrutions(degree ?? false)} />
        ) : (
          <TitleInstructions
            info={formationCourseInstrutions(degree ?? false)}
          />
        )}
        <Table>
          <thead>
            <tr>
              <th>Nome do Curso</th>
              <th>Carga Horária</th>
            </tr>
          </thead>
          <tbody>
            {certifications ? (
              certifications.map((certification) => (
                <tr key={certification.id}>
                  <td>{certification.name}</td>
                  <td>{certification.workload}</td>
                </tr>
              ))
            ) : (
              <>
                <div>{'Sem registro'}</div>
              </>
            )}
          </tbody>
        </Table>
        {uploadFile && (
          <UploadForm
            setUploadedFile={(file: File) => setUploadedFile(file)}
            degree={degree}
          />
        )}
      </Container>
    </StyledCardScreen>
  );
};

export default CertificationTable;
