import React from 'react';
import { IJob } from '../../models/job.model';
import { ISO8601ToDate } from '../../utils/format';
import { Card } from './MySubscribeStyle';

const JobCardUpload: React.FC<{ jobDetails: IJob }> = ({ jobDetails }) => {
  const userStr = window.localStorage.getItem('user') || '{}';
  const user = JSON.parse(userStr);
  return (
    <Card>
      <h3>{window.localStorage.getItem('userName')}</h3>
      <h5>ID do candidato: {user.candidate?.id}</h5>
      <h5>CPF: {user.candidate?.cpf}</h5>
      <h5>
        Data de nascimento:{' '}
        {ISO8601ToDate(user?.candidate.birthdate).toLocaleDateString('pt')}
      </h5>
      <h4>
        {jobDetails.name} - {jobDetails.jobType}
      </h4>
    </Card>
  );
};

export default JobCardUpload;
