import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Container, FormControl } from 'react-bootstrap';
import ResponseModal from '../../../components/ResponseModal';
import { IAttachment } from '../../../models/application-process.model.js';
import {
  ErrorMessage,
  StyledCardScreen,
  StyledForm,
  StyledInputGroup,
} from './FormsStyles';
import TitleInstructions from '../../JobApplication/components/TitleInstructions';

interface FormErrors {
  name?: string;
}

const UploadForm: React.FC<{
  setUploadedFile: (file: File) => void;
  degree?: boolean;
}> = ({ setUploadedFile, degree }) => {
  const [uploaded, setUploaded] = useState<IAttachment | null>(null);

  const [errors, setErrors] = useState<FormErrors>({});
  const [name, setName] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    success: false,
    message: '',
  });
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [file, setFile] = useState({ name: '' });

  const onFileChange = (e: any) => {
    setFile({ name: e?.target.files[0]?.name });
    setUploadedFile(e?.target.files[0]);
  };

  return (
    <StyledCardScreen>
      <Container>
        <TitleInstructions info={''} />
        {errors && (
          <>
            {errors.name && (
              <ErrorMessage>
                <FontAwesomeIcon icon={faExclamationCircle} /> {errors.name}
              </ErrorMessage>
            )}
          </>
        )}
        {/*<label
          htmlFor="input-file"
          aria-setsize={100}
          style={{ width: "200px" }}
          className="btn  btn-outline-primary">
          <FontAwesomeIcon icon={faPaperclip} /> Anexar arquivo

            </label>*/}
        <StyledForm>
          <StyledInputGroup className="mb-3">
            <FormControl
              color="primary"
              type="file"
              id="input-file"
              onChange={onFileChange}
              //style={{ display: "none" }}
            />
          </StyledInputGroup>
        </StyledForm>
        {/*file?.name &&
          <p className="text-secondary"><FontAwesomeIcon icon={faFile} /> <b><i>{file?.name}</i></b></p>*/}

        <ResponseModal
          show={showModal}
          onClose={handleCloseModal}
          success={modalContent.success}
          message={modalContent.message}
        />
      </Container>
    </StyledCardScreen>
  );
};

export default UploadForm;
