import {
  faExclamationCircle,
  faPlus,
  faSave,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Container, FormControl } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import ResponseModal from '../../../../components/ResponseModal';
import { ICertification } from '../../../../models/application-process.model';
import DisclaimerNote from '../DisclaimerNote';
import {
  ErrorMessage,
  StyledCardScreen,
  StyledForm,
  StyledInputGroup,
} from '../FormsStyles';
import FormationCourseList from './FormationCourseList';
import { formationCourseInstrutions } from '../../InstructionsList';
import TitleInstructions from '../TitleInstructions';

const maxItens = 2;

interface FormErrors {
  name?: string;
  workload?: string;
}

const FormationCourseForm: React.FC<{
  setItens: (itens: ICertification[]) => void;
  degree: boolean;
  itens: ICertification[];
}> = ({ setItens, degree, itens }) => {
  const [editingExperience, setEditingExperience] =
    useState<ICertification | null>(null);

  const [errors, setErrors] = useState<FormErrors>({});
  const [name, setName] = useState('');
  const [workload, setWorkload] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    success: false,
    message: '',
  });
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const validateForm = () => {
    const newErrors: FormErrors = {};

    if (!name) {
      newErrors.name = 'Curso é obrigatório.';
    }
    if (!workload) {
      newErrors.workload = 'Carga Horária é obrigatório.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddOrSaveExperience = () => {
    if (itens.length >= maxItens && !editingExperience) {
      setModalContent({
        success: false,
        message: `Permitido somente ${maxItens} itens!`,
      });
      setShowModal(true);
      return;
    }

    if (!validateForm()) {
      return;
    }

    if (editingExperience) {
      const updatedExperiences = itens.map((exp) =>
        exp.id === editingExperience.id ? { ...exp, name, workload } : exp,
      );
      setItens(updatedExperiences);
      setEditingExperience(null);
    } else {
      const newExperience = {
        id: uuidv4(),
        name,
        workload,
      };
      setItens([...itens, newExperience]);
    }

    setName('');
    setWorkload('');
  };

  const handleEditExperience = (item: ICertification) => {
    setEditingExperience(item);
    setName(item.name);
    setWorkload(item.workload);
  };

  const handleRemoveExperience = (id: string) => {
    setItens(itens.filter((exp) => exp.id !== id));
  };

  return (
    <StyledCardScreen>
      <Container>
        <TitleInstructions info={formationCourseInstrutions(degree)} />
        {errors && (
          <>
            {errors.name && (
              <ErrorMessage>
                <FontAwesomeIcon icon={faExclamationCircle} /> {errors.name}
              </ErrorMessage>
            )}
            {errors.workload && (
              <ErrorMessage>
                <FontAwesomeIcon icon={faExclamationCircle} /> {errors.workload}
              </ErrorMessage>
            )}
          </>
        )}
        <StyledForm>
          <StyledInputGroup className="mb-3">
            <FormControl
              type="text"
              placeholder="Nome do Curso"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </StyledInputGroup>
          <StyledInputGroup className="mb-3">
            <FormControl
              type="number"
              placeholder="Carga Horária"
              value={workload}
              onChange={(e) => setWorkload(e.target.value)}
            />
          </StyledInputGroup>
        </StyledForm>

        {/* LIST E EDIT */}
        <Button
          variant="outline-primary"
          size="sm"
          onClick={handleAddOrSaveExperience}
        >
          <FontAwesomeIcon icon={editingExperience ? faSave : faPlus} />
          {editingExperience ? ' Salvar' : ' Adicionar'}
        </Button>
        {itens.length > 0 && (
          <FormationCourseList
            itens={itens}
            onEdit={handleEditExperience}
            onRemove={handleRemoveExperience}
          />
        )}
        <DisclaimerNote />
        <ResponseModal
          show={showModal}
          onClose={handleCloseModal}
          success={modalContent.success}
          message={modalContent.message}
        />
      </Container>
    </StyledCardScreen>
  );
};

export default FormationCourseForm;
