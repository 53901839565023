import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { httpAxios } from '../../utils/axios';
import { Job } from '../Process/InterfacesProcess';
import {
  ApplicationProcess,
  ApplicationProcessCandidate,
} from '../MySubscribe/MySubscribeTypes';

const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 30px auto;
  text-align: center;
`;

const FailureIcon = styled.div`
  color: red;
  font-size: 48px;
  margin-bottom: 20px;
`;

const AttentionIcon = styled.div`
  color: green;
  font-size: 48px;
  margin-bottom: 20px;
`;

const CodeHighlight = styled.span`
  font-weight: bold;
  color: #007bff;
`;

interface RouteState {
  applicationProcessesId?: string;
}

const CandidateDisapproved: React.FC = () => {
  const [registrationCode, setRegistrationCode] = useState('');
  const [processCode, setProcessCode] = useState('');
  const [job, setJob] = useState<Job | undefined>();
  const [applicationProcessesCandidates, setApplicationProcessesCandidates] =
    useState<ApplicationProcessCandidate | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [applicationProcess, setApplicationProcess] =
    useState<ApplicationProcess>();

  const getIsUserSubscribe = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const { data } = await httpAxios<unknown, any>(
        //`application-processes/candidates/self/${processesData.applicationProcessesId}`,
        `application-processes/candidates/self/${window.localStorage.getItem('applicationProcessesId')}`,
        'GET',
        headers,
      );
      setApplicationProcess(data);
      setProcessCode(
        data.applicationProcessesCandidates.applicationProcessesId,
      );
      setRegistrationCode(data.applicationProcessesCandidates.id);
      setJob(data.applicationProcessesJobs[0].job);
      setApplicationProcessesCandidates(data.applicationProcessesCandidates);
    } catch (error) {
      console.error('Erro ao buscar processos de aplicação:', error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getIsUserSubscribe();
  }, []);

  return (
    <>
      <Container>
        <AttentionIcon>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </AttentionIcon>
        <h3>Processo em andamento, fiquem atento as informações no site.</h3>
        <h4>
          Conforme edital, poderão ocorrer novas convocações mediante
          necessidade.
        </h4>
        <p>
          Número do processo: <CodeHighlight>{processCode}</CodeHighlight>
        </p>
        <p>
          Número da inscrição: <CodeHighlight>{registrationCode}</CodeHighlight>
        </p>
      </Container>
    </>
  );
};

export default CandidateDisapproved;
