import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormErrorsUpload } from '../pages/MySubscribe/MySubscribe';
import { ErrorMessage } from '../pages/MySubscribe/UploadForm/FormsStyles';

interface ResponseModalProps {
  show: boolean;
  onClose: () => void;
  errors: FormErrorsUpload;
}

const ResponseModalErroUpload: React.FC<ResponseModalProps> = ({
  show,
  onClose,
  errors,
}) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>❌ {'Faltou arquivos '} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errors.identityFile && (
          <ErrorMessage>
            <FontAwesomeIcon icon={faExclamationCircle} /> {errors.identityFile}
          </ErrorMessage>
        )}
        {errors.addressFile && (
          <ErrorMessage>
            <FontAwesomeIcon icon={faExclamationCircle} /> {errors.addressFile}
          </ErrorMessage>
        )}
        {errors.bachelorFile && (
          <ErrorMessage>
            <FontAwesomeIcon icon={faExclamationCircle} /> {errors.bachelorFile}
          </ErrorMessage>
        )}
        {errors.highSchoolFile && (
          <ErrorMessage>
            <FontAwesomeIcon icon={faExclamationCircle} />{' '}
            {errors.highSchoolFile}
          </ErrorMessage>
        )}
        {errors.elementaryFile && (
          <ErrorMessage>
            <FontAwesomeIcon icon={faExclamationCircle} />{' '}
            {errors.elementaryFile}
          </ErrorMessage>
        )}

        {errors.certificationFile && (
          <ErrorMessage>
            <FontAwesomeIcon icon={faExclamationCircle} />{' '}
            {errors.certificationFile}
          </ErrorMessage>
        )}
        {errors.degreeFile && (
          <ErrorMessage>
            <FontAwesomeIcon icon={faExclamationCircle} /> {errors.degreeFile}
          </ErrorMessage>
        )}
        {errors.internshipExperienceFile && (
          <ErrorMessage>
            <FontAwesomeIcon icon={faExclamationCircle} />{' '}
            {errors.internshipExperienceFile}
          </ErrorMessage>
        )}
        {errors.workExperienceFile && (
          <ErrorMessage>
            <FontAwesomeIcon icon={faExclamationCircle} />{' '}
            {errors.workExperienceFile}
          </ErrorMessage>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ResponseModalErroUpload;
