import React from 'react';
import { Container } from 'react-bootstrap';
import TitleInstructions from '../JobApplication/components/TitleInstructions';
import { IExperience } from '../../models/application-process.model';
import { formatDateJsZero } from '../../utils/format';
import { StyledCardScreen, Table } from './MySubscribeStyle';
import UploadForm from './UploadForm/UploadForm';
import {
  ExperiencesInstrutions,
  uploadExperiencesInstrutions,
} from './instructionListScreen';

interface InternshipExperienceTableProps {
  internships?: IExperience[];
  degree?: boolean;
  setAttachment: (file: File) => void;
  uploadFile?: boolean;
}

const InternshipExperienceTable: React.FC<InternshipExperienceTableProps> = ({
  internships,
  degree,
  setAttachment,
  uploadFile = false,
}) => {
  const setUploadedFile = (file: File) => {
    setAttachment(file);
  };

  return (
    <StyledCardScreen>
      <Container>
        {uploadFile ? (
          <TitleInstructions
            info={uploadExperiencesInstrutions(degree ?? false)}
          />
        ) : (
          <TitleInstructions info={ExperiencesInstrutions(degree ?? false)} />
        )}
        <Table>
          <thead>
            <tr>
              <th>Intituição</th>
              <th>Madalidade</th>
              <th>Função</th>
              <th>Data Início</th>
              <th>Data Término</th>
            </tr>
          </thead>
          <tbody>
            {internships ? (
              internships.map((internship) => (
                <tr key={internship.id}>
                  <td>{internship.institution}</td>
                  <td>{internship.modality}</td>
                  <td>{internship.role}</td>
                  <td>{formatDateJsZero(internship.initDate)}</td>
                  <td>{formatDateJsZero(internship.endDate)}</td>
                </tr>
              ))
            ) : (
              <>
                <div>{'Sem registro'}</div>
              </>
            )}
          </tbody>
        </Table>
        {uploadFile && (
          <UploadForm
            setUploadedFile={(file: File) => setUploadedFile(file)}
            degree={degree}
          />
        )}
      </Container>
    </StyledCardScreen>
  );
};

export default InternshipExperienceTable;
