export const btn: React.CSSProperties = {
  borderRadius: '5px',
  backgroundColor: 'green',
  color: 'white',
  border: 'none',
  padding: '0.4rem',
  marginTop: '1.2rem',
  width: '180px',
  height: '44px',
};

export const btnback: React.CSSProperties = {
  borderRadius: '5px',
  backgroundColor: 'transparent',
  color: 'red',
  padding: '0.5rem',
  width: '100px',
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  border: '2px solid red',
};

export const boxbtn: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
};
