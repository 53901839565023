import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import { IExperience } from '../../../../models/application-process.model';
import { formatDate, formatDateJsZero } from '../../../../utils/format';
import { StyledCard, StyledTable } from '../FormsStyles';

type ExperienceListProps = {
  experiences: IExperience[];
  onEdit: (experience: IExperience) => void;
  onRemove: (id: string) => void;
};

const ExperienceList: React.FC<ExperienceListProps> = ({
  experiences,
  onEdit,
  onRemove,
}) => {
  return (
    <>
      <StyledCard>
        {experiences.map((exp) => (
          <div
            key={exp.id}
            style={{
              margin: '10px',
              borderBottom: '1px solid #ddd',
              paddingBottom: '10px',
            }}
          >
            <div>
              <strong>Função:</strong> {exp.role}
            </div>
            <div>
              <strong>Data de Início:</strong> {formatDate(exp.initDate)}
            </div>
            <div>
              <strong>Data de Término:</strong> {formatDate(exp.endDate)}
            </div>
            <div>
              <Button
                onClick={() => onEdit(exp)}
                variant="outline-primary"
                size="sm"
                style={{ marginRight: '5px' }}
              >
                <FontAwesomeIcon icon={faEdit} /> Editar
              </Button>
              <Button
                onClick={() => onRemove(exp.id)}
                variant="outline-danger"
                size="sm"
              >
                <FontAwesomeIcon icon={faTrashAlt} /> Remover
              </Button>
            </div>
          </div>
        ))}
      </StyledCard>
      <StyledTable striped bordered hover>
        <thead>
          <tr>
            <th>Insituição</th>
            <th>Função</th>
            <th>Data de Início</th>
            <th>Data de Término</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {experiences.map((exp) => (
            <tr key={exp.id}>
              <td>{exp.institution}</td>
              <td>{exp.role}</td>
              <td>{formatDateJsZero(exp.initDate)}</td>
              <td>{formatDateJsZero(exp.endDate)}</td>
              <td>
                <div>
                  <Button
                    onClick={() => onEdit(exp)}
                    variant="outline-primary"
                    size="sm"
                    style={{ marginRight: '5px' }}
                  >
                    <FontAwesomeIcon icon={faEdit} /> Editar
                  </Button>
                  <Button
                    onClick={() => onRemove(exp.id)}
                    variant="outline-danger"
                    size="sm"
                  >
                    <FontAwesomeIcon icon={faTrashAlt} /> Remover
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </>
  );
};

export default ExperienceList;
