import {
  faExclamationCircle,
  faPlus,
  faSave,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Container, FormControl, FormSelect } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import ResponseModal from '../../../../components/ResponseModal';
import { IExperience } from '../../../../models/application-process.model';
import {
  formatDateJsToCalendar,
  ISO8601ToDate,
} from '../../../../utils/format';
import { ExperiencesInstrutions } from '../../InstructionsList';
import DisclaimerNote from '../DisclaimerNote';
import {
  ErrorMessage,
  StyledCardScreen,
  StyledForm,
  StyledInputGroup,
} from '../FormsStyles';
import TitleInstructions from '../TitleInstructions';
import ExperienceList from './ExperienceList';

const options = ['Estágio não obrigatório', 'Voluntariado'];
const maxItens = 4;

interface FormErrors {
  modality?: string;
  institution?: string;
  role?: string;
  initDate?: string;
  endDate?: string;
}

const ExperienceForm: React.FC<{
  setExperiences: (experiences: IExperience[]) => void;
  degree: boolean;
  experiences: IExperience[];
}> = ({ setExperiences, degree, experiences }) => {
  const [editingExperience, setEditingExperience] =
    useState<IExperience | null>(null);

  const [errors, setErrors] = useState<FormErrors>({});
  const [institution, setInstitution] = useState('');
  const [modality, setModalidade] = useState<string | undefined>(
    'Estágio não obrigatório',
  );
  const [role, setFuncao] = useState('');
  const [initDateStr, setDataInicio] = useState('');
  const [endDateStr, setDataTermino] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    success: false,
    message: '',
  });
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const validateForm = () => {
    const newErrors: FormErrors = {};
    const initDate = ISO8601ToDate(initDateStr);
    const endDate = ISO8601ToDate(endDateStr);

    if (!institution) {
      newErrors.institution = 'Instituição é obrigatório.';
    }
    if (!modality) {
      newErrors.modality = 'Modalidade é obrigatório.';
    }
    if (!role) {
      newErrors.role = 'Função é obrigatório.';
    }
    if (!initDate) {
      newErrors.initDate = 'Data inicial é obrigatório.';
    }

    if (initDate.getFullYear() < 1900) {
      newErrors.initDate = 'Ano Inicial deve ser maior que 1900.';
    }

    if (!endDate) {
      newErrors.endDate = 'Data Final é obrigatório.';
    }

    if (endDate.getFullYear() < 1900) {
      newErrors.endDate = 'Ano Final deve ser maior que 1900.';
    }

    if (endDate.getTime() < initDate.getTime()) {
      newErrors.endDate = 'Data Final deve ser maior que data Inicial';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddOrSaveExperience = () => {
    if (experiences.length >= maxItens && !editingExperience) {
      setModalContent({
        success: false,
        message: `Permitido somente ${maxItens} itens!`,
      });
      setShowModal(true);
      return;
    }

    if (!validateForm()) {
      console.error('Formulário inválido, confira os campos!');
      return;
    }

    if (editingExperience) {
      const updatedExperiences = experiences.map((exp) =>
        exp.id === editingExperience.id
          ? {
              ...exp,
              institution,
              modality,
              role,
              initDate: initDateStr,
              endDate: endDateStr,
            }
          : exp,
      );
      setExperiences(updatedExperiences);
      setEditingExperience(null);
    } else {
      const newExperience = {
        id: uuidv4(),
        institution,
        modality,
        role,
        initDate: initDateStr,
        endDate: endDateStr,
      };
      setExperiences([...experiences, newExperience]);
    }

    setInstitution('');
    setModalidade('Estágio não obrigatório');
    setFuncao('');
    setDataInicio('');
    setDataTermino('');
  };

  const handleEditExperience = (experience: IExperience) => {
    setEditingExperience(experience);
    setInstitution(experience.institution);
    setModalidade(experience.modality);
    setFuncao(experience.role);
    setDataInicio(formatDateJsToCalendar(experience.initDate));
    setDataTermino(formatDateJsToCalendar(experience.endDate));
  };

  const handleRemoveExperience = (id: string) => {
    setExperiences(experiences.filter((exp) => exp.id !== id));
  };

  return (
    <StyledCardScreen>
      <Container>
        <TitleInstructions info={ExperiencesInstrutions(degree)} />
        {errors && (
          <>
            {errors.institution && (
              <ErrorMessage>
                <FontAwesomeIcon icon={faExclamationCircle} />{' '}
                {errors.institution}
              </ErrorMessage>
            )}
            {errors.modality && (
              <ErrorMessage>
                <FontAwesomeIcon icon={faExclamationCircle} /> {errors.modality}
              </ErrorMessage>
            )}
            {errors.role && (
              <ErrorMessage>
                <FontAwesomeIcon icon={faExclamationCircle} /> {errors.role}
              </ErrorMessage>
            )}
            {errors.initDate && (
              <ErrorMessage>
                <FontAwesomeIcon icon={faExclamationCircle} /> {errors.initDate}
              </ErrorMessage>
            )}
            {errors.endDate && (
              <ErrorMessage>
                <FontAwesomeIcon icon={faExclamationCircle} /> {errors.endDate}
              </ErrorMessage>
            )}
          </>
        )}
        <StyledForm>
          <StyledInputGroup className="mb-3">
            <FormControl
              type="text"
              placeholder="Instituição"
              value={institution}
              onChange={(e) => setInstitution(e.target.value)}
            />
          </StyledInputGroup>
          <StyledInputGroup className="mb-3">
            <FormSelect
              value={modality}
              onChange={(e) => setModalidade(e.target.value)}
            >
              {options.map((el) => (
                <option>{el}</option>
              ))}
            </FormSelect>
          </StyledInputGroup>
          <StyledInputGroup className="mb-3">
            <FormControl
              type="text"
              placeholder="Função"
              value={role}
              onChange={(e) => setFuncao(e.target.value)}
            />
          </StyledInputGroup>
          <StyledInputGroup className="mb-3">
            <FormControl
              type="date"
              placeholder="Data Início"
              value={initDateStr}
              onChange={(e) => setDataInicio(e.target.value)}
            />
          </StyledInputGroup>
          <StyledInputGroup className="mb-3">
            <FormControl
              type="date"
              placeholder="Data Término"
              value={endDateStr}
              onChange={(e) => setDataTermino(e.target.value)}
            />
          </StyledInputGroup>
        </StyledForm>
        <Button
          variant="outline-primary"
          size="sm"
          onClick={handleAddOrSaveExperience}
        >
          <FontAwesomeIcon icon={editingExperience ? faSave : faPlus} />
          {editingExperience ? ' Salvar' : ' Adicionar'}
        </Button>
        {experiences && experiences.length > 0 && (
          <ExperienceList
            experiences={experiences}
            onEdit={handleEditExperience}
            onRemove={handleRemoveExperience}
          />
        )}
        <DisclaimerNote />
        <ResponseModal
          show={showModal}
          onClose={handleCloseModal}
          success={modalContent.success}
          message={modalContent.message}
        />
      </Container>
    </StyledCardScreen>
  );
};

export default ExperienceForm;
