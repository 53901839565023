import React from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: flex;
  margin: 15px;
`;

const StyledCheckbox = styled.input`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

interface DeclarationCheckboxProps {
  onCheck: (isChecked: boolean) => void;
}

const DeclarationCheckbox: React.FC<DeclarationCheckboxProps> = ({
  onCheck,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onCheck(e.target.checked);
  };

  return (
    <CheckboxContainer>
      <StyledCheckbox
        type="checkbox"
        id="declarationCheckbox"
        onChange={handleChange}
      />
      <div>
        <h4>Declaro a veracidade de todos os documentos anexados.</h4>
        <Label htmlFor="declarationCheckbox">
          Declaro que li o edital, estou ciente e de acordo com todas as regras.
          Declaro ainda que todas as informações declaradas são verdadeiras, bem
          como os documentos comprobatórios anexados e que qualquer divergência,
          insuficiência, ou inobservância das regras, implicará na minha
          eliminação do presente edital, não cabendo posterior correção
        </Label>
      </div>
    </CheckboxContainer>
  );
};

export default DeclarationCheckbox;
