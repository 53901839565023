import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { httpAxios } from '../../utils/axios';
import { Answer, Question, TokenStart } from './ExamTypes';
import Progress from './Progress';
import Questionnaire from './Questionnaire';
import TimeUpMessage from './TimeUpMessage';

const MessageErrorContainer = styled.div`
  position: relative;
  max-width: 500px;
  margin: 20px auto;
  padding: 15px;
  border: 1px solid #dc3545;
  border-radius: 4px;
  background-color: #f8d7da;
  color: #721c24;
  font-weight: 700;
  text-align: center;
`;

const MessageSuccessContainer = styled.div`
  position: relative;
  max-width: 500px;
  margin: 20px auto;
  padding: 15px;
  border: 1px solid #28a745;
  border-radius: 4px;
  background-color: #d4edda;
  color: #155724;
  font-weight: 700;
  text-align: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #721c24;

  &:hover {
    background-color: #f5c6cb;
  }
`;

const QuestionsPage: React.FC = () => {
  const navigator = useNavigate();
  const [answeredCount, setAnsweredCount] = useState('0 / 0');
  const [questions, setQuestions] = useState<Question[]>([]);
  const [tokenStart, setTokenStart] = useState<TokenStart>();
  const [timeUp, setTimeUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const submitAnswer = async (answerId: string) => {
    setIsLoading(true);
    setError('');
    const registrationCodeLocal =
      window.localStorage.getItem('registrationCode');
    if (!registrationCodeLocal) {
      return;
    }
    try {
      const { data } = await httpAxios<unknown, any>(
        `test-results/${registrationCodeLocal}`,
        'PUT',
        { optionsId: answerId },
      );
      setIsLoading(false);
      setError('');
      setSuccessMessage('Sua resposta foi registrada com sucesso!');
    } catch (error) {
      let message = 'Erro ao enviar resposta. Por favor, tente novamente.';
      if (axios.isAxiosError(error)) {
        message = error.response?.data.message || message;
      } else if (error instanceof Error) {
        message = error.message;
      }
      setError(message);
    } finally {
      setIsLoading(false);
    }
  };

  const submitFinishQuestion = async () => {
    setIsLoading(true);
    setError('');
    try {
      const { data } = await httpAxios<unknown, any>(
        `test-results/finish/${window.localStorage.getItem(
          'registrationCode',
        )}`,
        'PUT',
      );
      setIsLoading(false);
      navigator('/exam-home');
    } catch (error) {
      let message = 'Erro ao finalizar prova. Por favor, tente novamente.';
      if (axios.isAxiosError(error)) {
        message = error.response?.data.message || message;
      } else if (error instanceof Error) {
        message = error.message;
      }
      setError(message);
    } finally {
      setIsLoading(false);
    }
  };

  const getQuestions = async () => {
    setIsLoading(true);
    setError('');
    const registrationCodeLocal =
      window.localStorage.getItem('registrationCode');
    if (!registrationCodeLocal) {
      return;
    }
    try {
      const { data } = await httpAxios<unknown, Question[]>(
        `test-results/${registrationCodeLocal}`,
        'GET',
      );
      setIsLoading(false);
      setQuestions(data);
    } catch (error) {
      let message = 'Erro ao finalizar prova. Por favor, tente novamente.';
      if (axios.isAxiosError(error)) {
        message = error.response?.data.message || message;
      } else if (error instanceof Error) {
        message = error.message;
      }
      setError(message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAnswer = (answer: Answer) => {
    submitAnswer(answer.id);
    setQuestions((questions) =>
      questions.map((q) =>
        q.id === answer.questionsId ? { ...q, answer: answer.id } : q,
      ),
    );
  };

  const onQuizComplete = () => {
    submitFinishQuestion();
  };

  const handleTimeUp = () => {
    setTimeUp(true);
  };

  useEffect(() => {
    const answered = questions.filter((q) => q.answer !== null).length;
    setAnsweredCount(`${answered} / ${questions.length}`);
  }, [questions]);

  useEffect(() => {
    const limitTimer = window.localStorage.getItem('timeLimit');
    getQuestions();
    setTokenStart({
      id: 'string',
      applicationProcessesCandidatesId: 'string',
      status: false,
      token: 'string',
      timeLimit: limitTimer ?? '',
      createdAt: 'string',
      updatedAt: 'string',
    });
  }, []);

  const handleCloseError = () => {
    setError('');
  };

  useEffect(() => {
    let timerId: any;

    if (successMessage) {
      timerId = setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    }

    return () => clearTimeout(timerId);
  }, [successMessage]);

  return (
    <>
      {tokenStart && (
        <>
          {!timeUp ? (
            <Progress
              answeredQuestions={answeredCount}
              timeLimit={tokenStart.timeLimit}
              onQuizComplete={onQuizComplete}
              onTimeUp={handleTimeUp}
            />
          ) : (
            <TimeUpMessage answeredCount={answeredCount} />
          )}
        </>
      )}
      {successMessage && (
        <MessageSuccessContainer>{successMessage}</MessageSuccessContainer>
      )}
      {error && (
        <MessageErrorContainer>
          {error}
          <CloseButton onClick={handleCloseError}>✖</CloseButton>
        </MessageErrorContainer>
      )}
      {!timeUp && (
        <Questionnaire
          isLoading={isLoading}
          questions={questions}
          onAnswer={handleAnswer}
        />
      )}
    </>
  );
};

export default QuestionsPage;
