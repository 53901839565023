import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ResponseModal from '../../components/ResponseModal';
import {
  IApplicationBody,
  IApplicationEditResponse,
  IApplicationResponse,
  ICertification,
  IDegree,
  IExperience,
  iGraduationData,
} from '../../models/application-process.model';
import { flexRow } from '../../styles/custom';
import { httpAxios } from '../../utils/axios';
import { boxbtn, btn, btnback } from './JobApplicationStyle';
import DeclarationCheckbox from './components/DeclarationCheckbox';
import ExperienceForm from './components/ExperienceComp/ExperienceForm';
import GraduationForm, {
  FormErrorsGraduation,
} from './components/GraduationComp/GraduationForm';

import DegreeForm from './components/DegreeForm/DegreeForm';
import ExperienceProForm from './components/ExperienceProComp/ExperienceProForm';
import FormationCourseForm from './components/FormationCourseForm/FormationCourseForm';

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: ${(props) => props.theme.background};

  @media (max-width: 768px) {
    max-width: 380px;
  }
`;

const EditSubscribe: React.FC = () => {
  const navigate = useNavigate();
  const [processesData, setProcessesData] =
    useState<IApplicationEditResponse>();
  const [proccessId, setProccessId] = useState<string>('');
  const [isDeclarationChecked, setIsDeclarationChecked] = useState(false);
  const [graduationData, setGraduationData] = useState<iGraduationData>({
    institution: '',
    modality: '',
    yearsEnd: '',
    numRegister: '',
  });
  const [errorsGraduation, setErrorsGraduation] =
    useState<FormErrorsGraduation>({});
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    success: false,
    message: '',
  });

  const [experiencesFromForm, setExperiencesFromForm] = useState<IExperience[]>(
    [],
  );
  const [jobExperiencesFromForm, setJobExperiencesFromForm] = useState<
    IExperience[]
  >([]);
  const [volunteersFromForm, setVolunteersFromForm] = useState<
    ICertification[]
  >([]);
  const [degreeeFromForm, setDegreeFromForm] = useState<IDegree[]>([]);

  const validateYear = (year: string) => {
    const isFourDigits = /^\d{4}$/.test(year);
    const isGreaterThan1900 = parseInt(year, 10) > 1900;
    return isFourDigits && isGreaterThan1900;
  };

  const validateGraduation = () => {
    const newErrors: FormErrorsGraduation = {};
    if (!processesData) {
      return false;
    }
    const isNumRegister = processesData.job.register
      ? !graduationData?.numRegister
      : false;
    const isValidRegister = processesData.job.register;

    if (!isValidRegister) {
      return true;
    }

    if (!graduationData.institution) {
      newErrors.institution = 'Instituição é obrigatório.';
    }
    if (!graduationData.modality) {
      newErrors.modality = 'Modalidade é obrigatório.';
    }
    if (!graduationData.yearsEnd) {
      newErrors.yearsEnd = 'Ano de conclusão é obrigatório.';
    } else if (!validateYear(graduationData.yearsEnd)) {
      newErrors.yearsEnd = 'O ano deve ter 4 dígitos e ser maior que 1900.';
    }

    if (isNumRegister) {
      if (!graduationData.numRegister) {
        newErrors.numRegister = 'Número do conselho é obrigatório.';
      }
    }

    setErrorsGraduation(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateGraduation2 = () => {
    if (!processesData) {
      return false;
    }
    const isNumRegister = processesData.job.register
      ? !graduationData?.numRegister
      : false;
    const isValidInputs =
      processesData.job.register || processesData.job.degree
        ? !graduationData?.institution ||
          !graduationData.modality ||
          !graduationData.yearsEnd
        : false;

    if (isValidInputs || isNumRegister) {
      setModalContent({
        success: false,
        message: 'Os campos de graduação incompletos!',
      });
      setShowModal(true);
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!processesData) {
      return false;
    }

    if (!validateGraduation()) {
      window.scrollTo(0, 0);
      return;
    }

    if (!isDeclarationChecked) {
      setModalContent({
        success: false,
        message: 'Precisa marcar a declaração de leitura do edital!',
      });
      setShowModal(true);
      return;
    }

    const requiredBody: IApplicationBody = {
      jobId: processesData.job.id,
      certifications: volunteersFromForm,
      workExps: jobExperiencesFromForm,
      internshipExps: experiencesFromForm,
    };
    const isSendGraduation =
      processesData.job.register || processesData.job.degree;
    if (isSendGraduation) {
      if (processesData.job.register) {
        requiredBody.register = graduationData?.numRegister ?? '';
      }
      requiredBody.institution = graduationData?.institution ?? '';
      requiredBody.course = graduationData?.modality ?? '';
      requiredBody.endDate = graduationData?.yearsEnd ?? '';
    }

    try {
      const { data } = await httpAxios<IApplicationBody, IApplicationResponse>(
        `application-processes/register/${proccessId}`,
        'PUT',
        processesData.job.degree
          ? { ...requiredBody, degrees: degreeeFromForm }
          : requiredBody,
      );

      window.localStorage.setItem('applicationProcessesId', proccessId);
      navigate('/mysubscribe');
    } catch (error) {
      let message =
        'Erro ao tentar conectar com servidor. Por favor, tente novamente.';
      if (axios.isAxiosError(error)) {
        message = error.response?.data.message || message;
      } else if (error instanceof Error) {
        message = error.message;
      }

      setModalContent({ success: false, message: message });
      setShowModal(true);
    }
  };

  const handleDeclarationCheck = (isChecked: boolean) => {
    setIsDeclarationChecked(isChecked);
  };

  const handleGraduationDataChange = useCallback((newData: iGraduationData) => {
    setGraduationData((prevData) => ({ ...prevData, ...newData }));
  }, []);

  const getIsFormRegistration = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const { data } = await httpAxios<unknown, IApplicationEditResponse>(
        `application-processes-candidate/self/${window.localStorage.getItem(
          'applicationProcessesId',
        )}`,
        'GET',
        headers,
      );
      setProcessesData(data);
      setExperiencesFromForm(data.internshipExps);
      setJobExperiencesFromForm(data.workExps);
      setVolunteersFromForm(data.certifications);
      setDegreeFromForm(data.degress);
      const graduation: iGraduationData = {
        institution: data.institution,
        modality: data.course,
        yearsEnd: data.endDate,
        numRegister: data.registerNumber,
      };
      handleGraduationDataChange(graduation);
    } catch (error) {
      console.error('Erro ao buscar processos de aplicação:', error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setProccessId(window.localStorage.getItem('applicationProcessesId') ?? '');
  }, []);

  useEffect(() => {
    getIsFormRegistration();
  }, [proccessId]);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      {processesData ? (
        <PageContainer>
          <div style={flexRow}>
            <div>
              {' '}
              <button onClick={handleBack} style={btnback}>
                Voltar
              </button>
            </div>
            <div>
              <strong>Nome do Cargo</strong>: {processesData.job.name}
            </div>
            <div>
              <strong>Graduação</strong>:{' '}
              {processesData.job.degree ? 'SIM' : 'NÃO'}
            </div>
          </div>
          {processesData.job.degree && (
            <GraduationForm
              errors={errorsGraduation}
              graduation={graduationData}
              onGraduationDataChange={handleGraduationDataChange}
              isRegisterNumber={processesData.job.register}
              isDegree={processesData.job.degree}
            />
          )}

          <FormationCourseForm
            degree={processesData.job.degree}
            setItens={(value) => setVolunteersFromForm(value)}
            itens={volunteersFromForm}
          />

          {processesData.job.degree && (
            <DegreeForm
              degree={processesData.job.degree}
              setDegrees={(value) => setDegreeFromForm(value)}
              Degrees={degreeeFromForm}
            />
          )}

          <ExperienceForm
            degree={processesData.job.degree}
            setExperiences={(value) => setExperiencesFromForm(value)}
            experiences={experiencesFromForm}
          />

          <ExperienceProForm
            degree={processesData.job.degree}
            setExperiences={(value) => setJobExperiencesFromForm(value)}
            experiences={jobExperiencesFromForm}
          />

          {/*declaração botão enviar*/}
          <DeclarationCheckbox onCheck={handleDeclarationCheck} />
          <div style={boxbtn}>
            {' '}
            <button onClick={handleSubmit} style={btn}>
              Enviar
            </button>
          </div>
          <ResponseModal
            show={showModal}
            onClose={() => setShowModal(false)}
            success={modalContent.success}
            message={modalContent.message}
          />
        </PageContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default EditSubscribe;
