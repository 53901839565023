export const theme = {
  primary: '#6B66F5',
  background: '#f8f9fa',
  border: '#cccccc',
  text: '#333333',
  textError: '#b81414',

  colors: {
    primary: '#007bff', // Azul
    secondary: '#6c757d', // Cinza
    success: '#28a745', // Verde
    danger: '#dc3545', // Vermelho
    warning: '#ffc107', // Amarelo
    info: '#17a2b8', // Azul claro
    light: '#f8f9fa', // Branco acinzentado
    dark: '#343a40', // Preto acinzentado
  },
};
