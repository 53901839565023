import React from 'react';
import styled from 'styled-components';

const Instructions = styled.p`
  color: #495057;
  font-size: 1rem;
  margin-top: 20px;
`;

const TitleInstructions: React.FC<{ info: string }> = ({ info }) => {
  return <Instructions>{info}</Instructions>;
};

export default TitleInstructions;
