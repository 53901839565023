import axios from 'axios';
import { differenceInYears } from 'date-fns';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICandidate, ICandidateBody } from '../../models/candidate.model';
import { httpAxios } from '../../utils/axios';

export interface RegisterFormState {
  id?: string;
  nome: string;
  cpf: string;
  email: string;
  telefone: string;
  senha: string;
  confirmSenha: string;
  pcd: boolean;
  birthDate: string;
}

interface RegisterFormErrors {
  nome?: string;
  cpf?: string;
  email?: string;
  telefone?: string;
  senha?: string;
  confirmSenha?: string;
  birthDate?: string;
}

const useRegisterForm = () => {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    success: false,
    message: '',
  });
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [formState, setFormState] = useState<RegisterFormState>({
    id: undefined,
    nome: '',
    cpf: '',
    email: '',
    telefone: '',
    senha: '',
    confirmSenha: '',
    birthDate: '',
    pcd: false,
  });
  const [errors, setErrors] = useState<RegisterFormErrors>({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  function validateBirthDate(birthDate: string): string | undefined {
    if (!birthDate) {
      return 'Data de Nascimento é obrigatório.';
    }

    const parsedDate = new Date(birthDate);
    const year = parsedDate.getFullYear();

    if (year < 1900) {
      return 'Ano deve ser maior que 1900.';
    }

    const age = differenceInYears(new Date(), parsedDate);
    if (age < 16) {
      return 'Idade deve ser maior ou igual a 16 anos.';
    }
  }

  const validateForm = () => {
    const newErrors: RegisterFormErrors = {};

    // Validação de nome
    if (!formState.nome) {
      newErrors.nome = 'Nome é obrigatório.';
    }

    // Validação de CPF (deve ter 11 dígitos e apenas números)
    const cpfRegex = /^\d{11}$/;
    if (!cpfRegex.test(formState.cpf)) {
      newErrors.cpf = 'CPF deve ter 11 dígitos.';
    }

    // Validação de e-mail
    // Utilize uma expressão regular mais apropriada conforme necessário
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formState.email)) {
      newErrors.email = 'E-mail inválido.';
    }

    // Validação de telefone (deve ter pelo menos 10 dígitos e apenas números)
    const telefoneRegex = /^\d{10,}$/;
    if (!telefoneRegex.test(formState.telefone)) {
      newErrors.telefone = 'Telefone deve ter pelo menos 10 dígitos.';
    }

    // Validação data nascimento
    const birthDateError = validateBirthDate(formState.birthDate);

    if (birthDateError) {
      newErrors.birthDate = birthDateError;
    } else {
      delete newErrors.birthDate;
    }

    // Validação de senha
    if (!formState.senha && !formState.id) {
      newErrors.senha = 'Senha é obrigatória.';
    } else if (formState.senha !== formState.confirmSenha) {
      newErrors.confirmSenha = 'As senhas não coincidem.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    const isValid = validateForm();

    if (!isValid) {
      console.error('Formulário inválido, confira os campos!', formState);
      return;
    }

    setIsLoading(true);

    try {
      const body: Partial<ICandidateBody> = {
        cpf: formState.cpf,
        email: formState.email,
        phone: formState.telefone,
        name: formState.nome,
        birthdate: formState.birthDate,
        pcd: formState.pcd,
      };

      if (formState.senha) {
        body.password = formState.senha;
      }

      const { data } = await httpAxios<Partial<ICandidateBody>, ICandidate>(
        formState.id ? `candidates/${formState.id}` : 'candidates',
        formState.id ? 'PATCH' : 'POST',
        body,
      );

      if (!data) {
        return;
      }

      setModalContent({
        success: true,
        message: 'Cadastro realizado com sucesso!',
      });

      setShowModal(true);

      navigate('/');
    } catch (error) {
      let message =
        'Erro ao tentar conectar com servidor. Por favor, tente novamente.';
      if (axios.isAxiosError(error)) {
        message = error.response?.data.message || message;
      } else if (error instanceof Error) {
        message = error.message;
      }

      setModalContent({ success: false, message: message });
      setShowModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    formState,
    setFormState,
    handleChange,
    handleSubmit,
    errors,
    handleCloseModal,
    modalContent,
    showModal,
    isLoading,
  };
};

export default useRegisterForm;
