import React, { useState } from 'react';
import styled from 'styled-components';
import { Answer, Question } from './ExamTypes';

type Props = {
  questions: Question[];
  onAnswer: (answer: Answer) => void;
  isLoading: boolean;
};

const QuestionnaireContainer = styled.div`
  max-width: 800px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const QuestionTitle = styled.h2`
  font-size: 1.25rem;
  margin-bottom: 25px;
  white-space: pre-wrap;
`;

const OptionList = styled.ul`
  list-style: none;
  padding: 0;
`;

type OptionItemProps = {
  isSelected: boolean;
};

const OptionItem = styled.li<OptionItemProps>`
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${(props) =>
    props.isSelected ? 'lightblue' : 'transparent'};

  &:hover {
    background-color: #f0f0f0;
  }
`;

const OptionLabel = styled.label<OptionItemProps>`
  display: block;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${(props) =>
    props.isSelected ? 'lightblue' : 'transparent'};

  &:hover {
    background-color: #f0f0f0;
  }
`;

const RadioInput = styled.input`
  margin-right: 10px;
`;

const LoadingIndicator = styled.div`
  text-align: center;
  margin: 20px 0;
  font-weight: 700;
  color: green;
`;

const Questionnaire: React.FC<Props> = ({ questions, onAnswer, isLoading }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const currentQuestion = questions[currentQuestionIndex];

  const handleSubmitAnswer = (option: Answer) => {
    if (!isLoading) {
      // Impede a seleção de uma nova resposta enquanto carrega
      onAnswer(option);
    }
    //setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  return (
    <>
      {currentQuestion && (
        <QuestionnaireContainer>
          <QuestionTitle>
            {/*`${currentQuestionIndex + 1}.`*/} {currentQuestion.description}
          </QuestionTitle>
          <OptionList>
            {currentQuestion.options.map((option) => (
              <OptionLabel
                key={option.id}
                isSelected={currentQuestion.answer === option.id}
                onClick={() => handleSubmitAnswer(option)}
              >
                <RadioInput
                  type="radio"
                  name="option"
                  checked={currentQuestion.answer === option.id}
                  onChange={() => handleSubmitAnswer(option)}
                />
                {option.description}
              </OptionLabel>
            ))}
          </OptionList>
          {isLoading ? (
            <LoadingIndicator>Enviando resposta...</LoadingIndicator>
          ) : (
            <div className="d-flex justify-content-between mt-3">
              <button
                className="btn btn-primary"
                onClick={() =>
                  setCurrentQuestionIndex(currentQuestionIndex - 1)
                }
                disabled={currentQuestionIndex === 0}
              >
                Anterior
              </button>
              <button
                className="btn btn-primary"
                onClick={() =>
                  setCurrentQuestionIndex(currentQuestionIndex + 1)
                }
                disabled={currentQuestionIndex === questions.length - 1}
              >
                Próximo
              </button>
            </div>
          )}
        </QuestionnaireContainer>
      )}
    </>
  );
};

export default Questionnaire;
