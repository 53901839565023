import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background-color: ${(props) => props.theme.background};
`;

export const Logo = styled.img`
  max-height: 85px;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const LogoXL = styled.img`
  max-height: 150px;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  max-width: 320px;
`;

export const ImageContainer = styled.div`
  max-width: 320px;
  margin-right: 80px;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Title = styled.div`
  color: ${(props) => props.theme.primary};
  font-size: 1rem;
  margin-bottom: 0.5em;
  font-weight: bold;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 0%.9;
  }
`;

export const Text = styled.p`
  color: #495057;
  margin-bottom: 20px;
`;

export const LoginForm = styled.form`
  width: 100%;
  max-width: 400px;
`;

export const Input = styled.input.attrs({
  className: 'form-control',
})`
  width: 100%;
  padding-right: 30px;
  margin-bottom: 10px;
`;

export const Button = styled.button`
  width: 100%;
  padding: 0.75rem 0;
  background-color: ${(props) => props.theme.primary};
  color: white;
  border: 1px solid ${(props) => props.theme.primary};
  border-radius: 0.25rem;
  font-size: 1rem;
  margin-bottom: 1rem;

  &:hover {
    background-color: darken(${(props) => props.theme.primary}, 10%);
    border-color: darken(${(props) => props.theme.primary}, 10%);
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const Link = styled.a`
  color: ${(props) => props.theme.primary};
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const EyeIcon = styled.button`
  position: absolute;
  right: 10px;
  top: 6px;
  border: none;
  background: none;
  cursor: pointer;
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 0.8rem;
  margin-top: -5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;
