export const formatDate = (dateString: string): string => {
  const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year}`;
};

export function formatDateJs(dateString: string) {
  const date = new Date(dateString);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Janeiro é 0!
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function formatDateJsToCalendar(dateString: string) {
  const [yyyy, mm, dd] = dateString.split('T')[0].split('-');
  return `${yyyy}-${mm}-${dd}`;
}

export function formatDateJsZero(dateString: string) {
  const [yyyy, mm, dd] = dateString.split('T')[0].split('-');
  return `${dd}/${mm}/${yyyy}`;
}

export function formatDateTimeJsZero(dateString: string) {
  const [datePart, timePart] = dateString.split('T');
  const [yyyy, mm, dd] = datePart.split('-');
  const [h, m, s] = timePart.split(':');

  return `${yyyy}-${mm}-${dd} ${h}:${m}:${s}`;
}

export const ISO8601ToDate = (dateStr: string) => new Date(dateStr + ' 00:00');
