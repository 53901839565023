import styled from 'styled-components';
import {
  Container as BootstrapContainer,
  Card,
  Table,
  Form,
  InputGroup,
} from 'react-bootstrap';

export const StyledForm = styled(Form)`
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    .form-group {
      margin-right: 15px;
    }
  }
`;

export const StyledCard = styled(Card)`
  margin-top: 15px;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const StyledCardScreen = styled(Card)`
  margin-top: 15px;
`;

export const StyledTable = styled(Table)`
  margin-top: 15px;
  display: none;
  @media (min-width: 768px) {
    display: table;
  }
`;

export const Input = styled.input.attrs({
  className: 'form-control',
})`
  width: 100%;
  padding-right: 30px;
  margin-bottom: 10px;
`;

export const StyledInputGroup = styled(InputGroup)`
  margin-right: 5px;
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 0.8rem;
  margin-top: -5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;
