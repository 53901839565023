import {
  faExclamationCircle,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import ideias from '../../assets/ideias.png';
import logo from '../../assets/logo.png';
import ResponseModal from '../../components/ResponseModal';
import { ILoginBody, ILoginResponse } from '../../models/login.model';
import { httpAxios } from '../../utils/axios';
import {
  Button,
  Container,
  Content,
  ErrorMessage,
  EyeIcon,
  FormContainer,
  ImageContainer,
  Input,
  InputContainer,
  LinkContainer,
  Logo,
  Title,
} from './LoginStyles';

interface LoginFormErrors {
  cpf?: string;
  senha?: string;
}

interface ILoginProps {
  setAuthenticated: (value: boolean) => void;
}

const Login = ({ setAuthenticated }: ILoginProps) => {
  const [cpf, setCpf] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    success: false,
    message: '',
  });
  const [errors, setErrors] = useState<LoginFormErrors>({});
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors: LoginFormErrors = {};

    const cpfRegex = /^\d{11}$/;
    if (!cpfRegex.test(cpf)) {
      newErrors.cpf = 'CPF deve ter 11 dígitos.';
    }

    if (!password) {
      newErrors.senha = 'Senha é obrigatória.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLogin = async () => {
    if (isLoading) {
      return;
    }

    const isValid = validateForm();
    if (!isValid) {
      console.error('Formulário inválido, confira os campos!');
      return;
    }

    setIsLoading(true);
    try {
      const { data } = await httpAxios<ILoginBody, ILoginResponse>(
        'auth',
        'POST',
        { username: cpf, password: password },
      );

      if (!data) {
        setAuthenticated(false);
        return;
      }

      setAuthenticated(true);
      window.localStorage.setItem('accessToken', data.accessToken);
      window.localStorage.setItem('userName', data.user.name);
      window.localStorage.setItem('user', JSON.stringify(data.user));

      navigate('/myprocesses');
    } catch (error) {
      let message = 'Erro no login. Por favor, tente novamente.';
      if (axios.isAxiosError(error)) {
        message = error.response?.data.message || message;
      } else if (error instanceof Error) {
        message = error.message;
      }
      setModalContent({ success: false, message: message });
      setShowModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container>
      <Logo src={logo} alt="FUNEV" />
      <Content>
        <ImageContainer>
          <img src={ideias} alt="Ideias" />
        </ImageContainer>
        <FormContainer>
          <Title>Venha fazer parte do nosso time!</Title>
          <Input
            type="text"
            placeholder="CPF"
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
          />
          {errors.cpf && (
            <ErrorMessage>
              <FontAwesomeIcon icon={faExclamationCircle} /> {errors.cpf}
            </ErrorMessage>
          )}
          <InputContainer>
            <Input
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  handleLogin();
                }
              }}
              type={showPassword ? 'text' : 'password'}
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <EyeIcon onClick={togglePasswordVisibility}>
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </EyeIcon>
          </InputContainer>
          {errors.senha && (
            <ErrorMessage>
              <FontAwesomeIcon icon={faExclamationCircle} /> {errors.senha}
            </ErrorMessage>
          )}
          <LinkContainer>
            <Link to="/register">Me cadastrar</Link>
            <Link to="/forgot-password">Esqueci minha senha</Link>
          </LinkContainer>
          <Button onClick={handleLogin} disabled={isLoading}>
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Entrar'
            )}
          </Button>
        </FormContainer>
      </Content>
      <ResponseModal
        show={showModal}
        onClose={() => setShowModal(false)}
        success={modalContent.success}
        message={modalContent.message}
      />
    </Container>
  );
};

export default Login;
