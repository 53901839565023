import { faFile, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { keyframes, styled } from 'styled-components';
import { Job } from '../MySubscribe/MySubscribeTypes';
import { httpAxios } from '../../utils/axios';
import { formatDateTimeJsZero } from '../../utils/format';
import { TokenStart } from './ExamTypes';
import InstructionsComponent from './InstructionsComponent';

const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 30px auto;
  text-align: center;
`;

const SuccessIcon = styled.div`
  color: green;
  font-size: 48px;
  margin-bottom: 20px;
`;

const CodeHighlight = styled.span`
  font-weight: bold;
  color: #007bff;
`;
const JobHighlight = styled.span`
  font-weight: bold;
  color: black;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingIndicator = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px; // Ajuste conforme necessário
  color: #007bff; // Cor do texto e do ícone

  & > p {
    margin-top: 10px;
    font-weight: bold;
  }

  & .fa-spinner {
    animation: ${rotate} 2s linear infinite;
  }
`;

const MessageErrorContainer = styled.div`
  position: relative;
  max-width: 500px;
  margin: 20px auto;
  padding: 15px;
  border: 1px solid #dc3545;
  border-radius: 4px;
  background-color: #f8d7da;
  color: #721c24;
  font-weight: 700;
  text-align: center;
`;

const MessageSuccessContainer = styled.div`
  position: relative;
  max-width: 500px;
  margin: 20px auto;
  padding: 15px;
  border: 1px solid #28a745;
  border-radius: 4px;
  background-color: #d4edda;
  color: #155724;
  font-weight: 700;
  text-align: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #721c24;

  &:hover {
    background-color: #f5c6cb;
  }
`;

let registerCodeLocal = '';

const ProvaHomePage: React.FC = () => {
  const navigator = useNavigate();

  const [registrationCode, setRegistrationCode] = React.useState('');
  const [processCode, setProcessCode] = React.useState('');
  const [job, setJob] = React.useState<Job | undefined>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isTimeOut, setTimeout] = React.useState(false);
  const [error, setError] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState('');

  const [showModal, setShowModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState({
    success: false,
    message: '',
  });

  const getIsUserSubscribe = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const { data } = await httpAxios<unknown, any>(
        `application-processes/candidates/self/${window.localStorage.getItem(
          'applicationProcessesId',
        )}`,
        'GET',
        headers,
      );
      registerCodeLocal = data.applicationProcessesCandidates.id;
      setProcessCode(
        data.applicationProcessesCandidates.applicationProcessesId,
      );
      setRegistrationCode(registerCodeLocal);
      setJob(data.applicationProcessesJobs[0].job);
      window.localStorage.setItem('registrationCode', registerCodeLocal);
    } catch (error) {
      console.error('Erro ao buscar processos de aplicação:', error);
    }
  };

  const startExam = async () => {
    setIsLoading(true);
    if (!registerCodeLocal) {
      return;
    }
    try {
      const { data } = await httpAxios<unknown, TokenStart>(
        `test-results/${registerCodeLocal}/token`,
        'POST',
      );
      setIsLoading(false);
      const dtLimit = formatDateTimeJsZero(data.timeLimit);
      window.localStorage.setItem('timeLimit', dtLimit);
      navigator('/questions');
    } catch (error) {
      let message = 'Erro ao finalizar prova. Por favor, tente novamente.';
      if (axios.isAxiosError(error)) {
        message = error.response?.data.message || message;
      } else if (error instanceof Error) {
        message = error.message;
      }
      setError(message);
    } finally {
      setIsLoading(false);
    }
  };
  const checkExam = async () => {
    setIsLoading(true);
    if (!registerCodeLocal) {
      return;
    }
    try {
      const { data } = await httpAxios<unknown, TokenStart>(
        `test-results/${registerCodeLocal}/token`,
        'GET',
      );
      setIsLoading(false);
      const dtLimit = formatDateTimeJsZero(data.timeLimit);
      window.localStorage.setItem('timeLimit', dtLimit);
      navigator('/questions');
    } catch (error) {
      let message = 'Erro ao finalizar prova. Por favor, tente novamente.';
      if (axios.isAxiosError(error)) {
        const isNotStarted = error.response?.status === 404;
        const isTimeout =
          error.response?.status === 403 || error.response?.status === 401;
        if (isTimeout) {
          setSuccessMessage(
            'Você já finalizou sua prova, aguarde publicação conforme previsto no cronograma.',
          );
          setTimeout(isTimeout);
        } else {
          setTimeout(isTimeout);
          message = error.response?.data.message || message;
        }
      } else if (error instanceof Error) {
        message = error.message;
        console.error(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    checkExam();
  }, [registrationCode]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getIsUserSubscribe();
  }, []);

  const handleCloseError = () => {
    setError('');
  };

  return (
    <>
      <Container>
        {isLoading ? (
          <LoadingIndicator>
            <FontAwesomeIcon
              icon={faSpinner}
              size="2x"
              className="fa-spinner"
            />
            <p>Carregando...</p>
          </LoadingIndicator>
        ) : (
          <>
            <SuccessIcon>
              <FontAwesomeIcon icon={faFile} />
            </SuccessIcon>
            <h3>Prova avaliativa</h3>
            <p>
              Número do processo: <CodeHighlight>{processCode}</CodeHighlight>
            </p>
            <p>
              Número da inscrição:{' '}
              <CodeHighlight>{registrationCode}</CodeHighlight>
            </p>
            <p>
              Vaga <JobHighlight>{job?.name}</JobHighlight>
            </p>
            {successMessage && (
              <MessageSuccessContainer>
                {successMessage}
              </MessageSuccessContainer>
            )}
            {error && (
              <MessageErrorContainer>
                {error}
                <CloseButton onClick={handleCloseError}>✖</CloseButton>
              </MessageErrorContainer>
            )}
            {!isTimeOut && <InstructionsComponent onStart={startExam} />}
          </>
        )}
      </Container>
    </>
  );
};

export default ProvaHomePage;
