import React from 'react';
import styled from 'styled-components';

const Note = styled.p`
  color: #6c757d;
  font-size: 0.9rem;
  margin-top: 10px;
  border-left: 3px solid #ffc107;
  padding-left: 10px;
`;

const DisclaimerNote: React.FC = () => {
  return (
    <Note>
      Os documentos anexados seguem os termos do item 2.7, quadro de
      documentação do edital, não inclua nenhum documento que não seja
      relacionado com a área pretendida, ou que você não tenha como comprovar.
    </Note>
  );
};

export default DisclaimerNote;
