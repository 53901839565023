import React, { useEffect, useState } from 'react';
import { Container, FormControl } from 'react-bootstrap';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ErrorMessage,
  StyledCardScreen,
  StyledForm,
  StyledInputGroup,
} from '../FormsStyles';
import TitleInstructions from '../TitleInstructions';
import { GraduationInstrutions } from '../../InstructionsList';
import { iGraduationData } from '../../../../models/application-process.model';

interface GraduationFormProps {
  onGraduationDataChange: (data: iGraduationData) => void;
  isRegisterNumber: boolean;
  isDegree: boolean;
  graduation: iGraduationData;
  errors: FormErrorsGraduation;
}

export interface FormErrorsGraduation {
  modality?: string;
  institution?: string;
  yearsEnd?: string;
  numRegister?: string;
}

const GraduationForm: React.FC<GraduationFormProps> = ({
  onGraduationDataChange,
  isRegisterNumber,
  isDegree,
  graduation,
  errors,
}) => {
  const [institution, setInstitution] = useState(graduation.institution);
  const [modality, setModalidade] = useState(graduation.modality);
  const [yearsEnd, setYearsEnd] = useState(graduation.yearsEnd);
  const [numRegister, setNumRegister] = useState(graduation.numRegister);

  useEffect(() => {
    onGraduationDataChange({ institution, modality, yearsEnd, numRegister });
  }, [institution, modality, yearsEnd, numRegister, onGraduationDataChange]);

  return (
    <StyledCardScreen>
      <Container>
        <TitleInstructions info={GraduationInstrutions()} />
        {errors && (
          <>
            {errors.institution && (
              <ErrorMessage>
                <FontAwesomeIcon icon={faExclamationCircle} />{' '}
                {errors.institution}
              </ErrorMessage>
            )}
            {errors.modality && (
              <ErrorMessage>
                <FontAwesomeIcon icon={faExclamationCircle} /> {errors.modality}
              </ErrorMessage>
            )}
            {errors.yearsEnd && (
              <ErrorMessage>
                <FontAwesomeIcon icon={faExclamationCircle} /> {errors.yearsEnd}
              </ErrorMessage>
            )}
            {errors.numRegister && (
              <ErrorMessage>
                <FontAwesomeIcon icon={faExclamationCircle} />{' '}
                {errors.numRegister}
              </ErrorMessage>
            )}
          </>
        )}
        <StyledForm>
          {(isDegree || isRegisterNumber) && (
            <StyledInputGroup className="mb-3">
              <FormControl
                type="text"
                placeholder="Curso"
                value={modality}
                onChange={(e) => setModalidade(e.target.value)}
              />
            </StyledInputGroup>
          )}
          <StyledInputGroup className="mb-3">
            <FormControl
              type="text"
              placeholder="Instituição de ensino"
              value={institution}
              onChange={(e) => setInstitution(e.target.value)}
            />
          </StyledInputGroup>
          <StyledInputGroup className="mb-3">
            <FormControl
              type="number"
              placeholder="Ano de conclusão"
              value={yearsEnd}
              onChange={(e) => setYearsEnd(e.target.value)}
            />
          </StyledInputGroup>
          {isRegisterNumber && (
            <StyledInputGroup className="mb-3">
              <FormControl
                type="number"
                placeholder="Nº conselho"
                value={numRegister}
                onChange={(e) => setNumRegister(e.target.value)}
              />
            </StyledInputGroup>
          )}
        </StyledForm>
      </Container>
    </StyledCardScreen>
  );
};

export default GraduationForm;
