import React from 'react';
import { Container } from 'react-bootstrap';
import { degreeOptions } from '../JobApplication/InstructionsList';
import TitleInstructions from '../JobApplication/components/TitleInstructions';
import { IDegree } from '../../models/application-process.model';
import { StyledCardScreen, Table } from './MySubscribeStyle';
import UploadForm from './UploadForm/UploadForm';
import {
  DegreeInstrutions,
  uploadDegreeInstrutions,
} from './instructionListScreen';

interface DegreeTableProps {
  degress?: IDegree[];
  degree?: boolean;
  setAttachment: (file: File) => void;
  uploadFile?: boolean;
}

const DegreeTable: React.FC<DegreeTableProps> = ({
  degress,
  degree,
  setAttachment,
  uploadFile = false,
}) => {
  const getNameDegree = (degreeId: string): string => {
    const degree = degreeOptions.find((option) => option.id === degreeId);
    return degree ? degree.name : '';
  };

  const setUploadedFile = (file: File) => {
    setAttachment(file);
  };

  return (
    <StyledCardScreen>
      <Container>
        {uploadFile ? (
          <TitleInstructions info={uploadDegreeInstrutions(degree ?? false)} />
        ) : (
          <TitleInstructions info={DegreeInstrutions(degree ?? false)} />
        )}
        <Table>
          <thead>
            <tr>
              <th>Nome do Curso</th>
              <th>Carga Horária</th>
              <th>Nível</th>
            </tr>
          </thead>
          <tbody>
            {degress ? (
              degress.map((degree) => (
                <tr key={degree.id}>
                  <td>{degree.name}</td>
                  <td>{degree.workload}</td>
                  <td>{getNameDegree(degree.degreeLevelsId)}</td>
                </tr>
              ))
            ) : (
              <>
                <div>{'Sem registro'}</div>
              </>
            )}
          </tbody>
        </Table>
        {uploadFile && (
          <UploadForm
            setUploadedFile={(file: File) => setUploadedFile(file)}
            degree={degree}
          />
        )}
      </Container>
    </StyledCardScreen>
  );
};

export default DegreeTable;
