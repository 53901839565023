import React from 'react';
import { Container } from 'react-bootstrap';
import TitleInstructions from '../JobApplication/components/TitleInstructions';
import { IExperience } from '../../models/application-process.model';
import { formatDateJsZero } from '../../utils/format';
import { StyledCardScreen, Table } from './MySubscribeStyle';
import UploadForm from './UploadForm/UploadForm';
import {
  ExperiencesProInstrutions,
  uploadExperiencesProInstrutions,
} from './instructionListScreen';

interface WorkExperienceTableProps {
  experiences?: IExperience[];
  degree?: boolean;
  setAttachment: (file: File) => void;
  uploadFile?: boolean;
}

const WorkExperienceTable: React.FC<WorkExperienceTableProps> = ({
  experiences,
  degree,
  setAttachment,
  uploadFile = false,
}) => {
  const setUploadedFile = (file: File) => {
    setAttachment(file);
  };

  return (
    <StyledCardScreen>
      <Container>
        {uploadFile ? (
          <TitleInstructions
            info={uploadExperiencesProInstrutions(degree ?? false)}
          />
        ) : (
          <TitleInstructions
            info={ExperiencesProInstrutions(degree ?? false)}
          />
        )}
        <Table>
          <thead>
            <tr>
              <th>Instituição</th>
              <th>Tipo Unidade</th>
              <th>Cargo</th>
              <th>Data Início</th>
              <th>Data Término</th>
            </tr>
          </thead>
          <tbody>
            {experiences ? (
              experiences.map((experience) => (
                <tr key={experience.id}>
                  <td>{experience.institution}</td>
                  <td>{experience.modality}</td>
                  <td>{experience.role}</td>
                  <td>{formatDateJsZero(experience.initDate)}</td>
                  <td>{formatDateJsZero(experience.endDate)}</td>
                </tr>
              ))
            ) : (
              <>
                <div>{'Sem registro'}</div>
              </>
            )}
          </tbody>
        </Table>
        {uploadFile && (
          <UploadForm
            setUploadedFile={(file: File) => setUploadedFile(file)}
            degree={degree}
          />
        )}
      </Container>
    </StyledCardScreen>
  );
};

export default WorkExperienceTable;
