import React from 'react';
import { Container } from 'react-bootstrap';
import TitleInstructions from '../JobApplication/components/TitleInstructions';
import {
  uploadAddress,
  uploadIdentity,
  uploadReg,
  uploadSchool,
} from './instructionListScreen';
import { StyledCardScreen } from './MySubscribeStyle';
import UploadForm from './UploadForm/UploadForm';

interface AddressProps {
  degree?: boolean;
  register?: boolean;
  jobType: string;
  setAttachmentIdentity: (file: File) => void;
  setAttachmentAddress: (file: File) => void;
  setAttachmentSchool: (file: File) => void;
  setAttachmentReg: (file: File) => void;
}

const UploadAddress: React.FC<AddressProps> = ({
  degree,
  register,
  jobType,
  setAttachmentIdentity,
  setAttachmentAddress,
  setAttachmentSchool,
  setAttachmentReg,
}) => {
  const setUploadedAddressFile = (file: File) => {
    setAttachmentAddress(file);
  };
  const setSchoolFile = (file: File) => {
    setAttachmentSchool(file);
  };
  const setRegFile = (file: File) => {
    setAttachmentReg(file);
  };

  return (
    <StyledCardScreen>
      <Container>
        <TitleInstructions info={uploadIdentity()} />
        <UploadForm
          setUploadedFile={(file: File) => setAttachmentIdentity(file)}
          degree={degree}
        />
        <TitleInstructions info={uploadAddress()} />
        <UploadForm
          setUploadedFile={(file: File) => setUploadedAddressFile(file)}
          degree={degree}
        />
        <TitleInstructions info={uploadSchool(jobType, degree ?? false)} />
        <UploadForm
          setUploadedFile={(file: File) => setSchoolFile(file)}
          degree={degree}
        />
        {register && (
          <>
            <TitleInstructions info={uploadReg()} />
            <UploadForm
              setUploadedFile={(file: File) => setRegFile(file)}
              degree={degree}
            />
          </>
        )}
      </Container>
    </StyledCardScreen>
  );
};

export default UploadAddress;
