import { Card as BootstrapCard } from 'react-bootstrap';
import styled from 'styled-components';
import { theme } from '../../styles/theme';

export const ProcessPageContainer = styled.div`
  max-width: 800px; // ou a largura máxima que você desejar
  margin: auto;
  padding: 20px;
`;

export const TableCard = styled(BootstrapCard)`
  margin-top: 20px;
  background-color: ${theme.background}; // ou qualquer cor específica do seu tema
`;

export const TableHeader = styled.thead`
  th {
    color: ${theme.colors
      .secondary}; // Defina como cinza ou outra cor de sua preferência do tema
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  margin-top: 1rem;
  border-collapse: collapse;

  th {
    background-color: ${theme.colors.primary};
    color: white;
  }

  thead th {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  tr:nth-child(even) {
    background-color: ${theme.colors.light};
  }

  tr:hover {
    background-color: ${theme.colors.info};
    cursor: pointer;
  }

  th,
  td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid ${theme.colors.secondary};
  }
`;

export const Container = styled.div`
  padding: 20px;
`;

export const Title = styled.h2`
  color: ${(props) => props.theme.primary};
  font-size: 1rem;
  margin-bottom: 0.5em;
  font-weight: bold;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 0%.9;
  }
`;

interface LinkButtonProps {
  buttonType?: 'edital' | 'vagas' | 'resultado' | 'open';
  hidden?: boolean;
}

const getButtonColor = (buttonType?: string) => {
  switch (buttonType) {
    case 'edital':
      return '#ADD8E6';
    case 'vagas':
      return '#90EE90';
    case 'resultado':
      return '#ffe2ad';
    case 'open':
      return '#ffe200';
    case 'agendado':
      return '#5DADE2';
    default:
      return 'inherit';
  }
};

export const LinkButton = styled.a<LinkButtonProps>`
  display: inline-block;
  padding: 2px 10px;
  margin: 5px;
  background-color: ${({ buttonType }) => getButtonColor(buttonType)};
  color: #000;
  text-align: center;
  text-decoration: none;
  border-radius: 10px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  font-size: 0.8rem;

  &:hover {
    opacity: 0.9;
  }

  display: ${({ hidden }) => (hidden ? 'none' : 'inline-block')};
`;

interface StatusButtonProps {
  buttonType?: 'Aberto' | 'Em Andamento' | 'Encerrado' | 'Agendado';
}

export const StatusButton = styled.a<StatusButtonProps>`
  display: inline-block;
  padding: 2px 10px;
  margin: 5px;
  background-color: ${({ buttonType }) =>
    buttonType === 'Aberto'
      ? 'green'
      : buttonType === 'Agendado'
        ? '#5DADE2'
        : '#ffc4c4'};
  color: ${({ buttonType }) =>
    buttonType === 'Aberto' ? '#FFFFFF' : '#000000'};
  text-align: center;
  text-decoration: none;
  border-radius: 10px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  font-size: 0.8rem;

  &:hover {
    opacity: 0.9;
  }
`;
