import { styled } from 'styled-components';

export const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Email = styled.span`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  font-weight: 600;
  @media (max-width: 768px) {
    max-width: 120px;
  }
`;

export const LogoutButton = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
`;
