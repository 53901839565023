import styled from 'styled-components';

// Card Style
export const Card = styled.div`
  margin: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;

  @media (max-width: 768px) {
  }
`;

// Table Style
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f4f4f4;
  }

  @media (max-width: 768px) {
  }
`;

export const StyledCardScreen = styled(Card)`
  margin-top: 15px;
`;

export const StyledButtonPrint = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin: 0 auto;
  display: block;

  &:hover {
    background-color: #45a049;
  }

  svg {
    margin-right: 8px;
  }
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  line-height: 1.5;
  font-size: 16px;
`;
