export const formationCourseInstrutions = (degree: boolean): string =>
  degree
    ? 'Curso(s) de extensão e/ou complementares na área de atuação'
    : 'Curso(s) complementares na área de atuação';

export const DegreeInstrutions = (degree: boolean): string =>
  degree
    ? 'Curso(s) de Pós-Graduação, Mestrado e/ou Doutorado em áreas afins do cargo pretendido com carga horária mínima válida conforme legislação vigente.'
    : 'Curso(s) de Pós-Graduação, Mestrado e/ou Doutorado em áreas afins do cargo pretendido com carga horária mínima válida conforme legislação vigente.';

export const ExperiencesInstrutions = (degree: boolean): string =>
  degree
    ? 'Experiência de voluntariado, ou de estágio não obrigatório na área pretendida. Preencher quantos campos for necessário, se for necessário apenas 1 campo, preencha apenas 1.'
    : 'Experiência de voluntariado, ou de estágio não obrigatório na área pretendida. Preencher quantos campos for necessário, se for necessário apenas 1 campo, preencha apenas 1.';
export const ExperiencesProInstrutions = (degree: boolean): string =>
  degree
    ? 'Experiência profissional na área pretendida. Preencher quantos campos for necessário, se for necessário apenas 1 campo, preencha apenas 1.'
    : 'Experiência profissional na área pretendida. Preencher quantos campos for necessário, se for necessário apenas 1 campo, preencha apenas 1.';

export const GraduationInstrutions = (): string =>
  ' Declare, sua gradução ou formação tecnica.';

export const uploadCourseInstrutions = (degree: boolean): string =>
  degree
    ? 'Anexe aqui, caso tenha declarado, comprovantes do(s) curso(s) de extensão e/ou complementares na área de atuação, compilados em um único arquivo'
    : 'Anexe aqui, caso tenha declarado, comprovantes do(s) curso(s) complementares na área de atuação, compilados em um único arquivo';
export const uploadDegreeInstrutions = (degree: boolean): string =>
  degree
    ? 'Anexe aqui, caso tenha declarado, comprovantes do(s) curso(s) de Pós-Graduação, Mestrado e/ou Doutorado em áreas afins do cargo pretendido com carga horária mínima válida conforme legislação vigente, compilados em um único arquivo'
    : 'Anexe aqui, caso tenha declarado, comprovantes do(s) curso(s) de Pós-Graduação, Mestrado e/ou Doutorado em áreas afins do cargo pretendido com carga horária mínima válida conforme legislação vigente, compilados em um único arquivo';
export const uploadExperiencesInstrutions = (degree: boolean): string =>
  degree
    ? 'Anexe aqui, caso tenha declarado, comprovantes de experiência profissional na área pretendida, compilados em um único arquivo'
    : 'Anexe aqui, caso tenha declarado, comprovantes de experiência de voluntariado, ou de estágio não obrigatório na área pretendida, compilados em um único arquivo';
export const uploadExperiencesProInstrutions = (degree: boolean): string =>
  degree
    ? 'Anexe aqui, caso tenha declarado, comprovantes de experiência profissional na área pretendida, compilados em um único arquivo'
    : 'Anexe aqui, caso tenha declarado, comprovantes de experiência profissional na área pretendida, compilados em um único arquivo';

export const uploadIdentity = (): string =>
  'Anexe aqui seu comprovante de identificação (CPF)';

export const uploadAddress = (): string =>
  'Anexe aqui seu comprovante de endereço';
export const uploadDocsResources = (): string =>
  'Anexe em um arquivo único no formato pdf todos os documentos comprobatorios da sua inscrição da fase 1, incluindo comprovante de endereço e graduações caso se aplique.';

export const uploadSchool = (jobType: string, degree: boolean): string =>
  jobType.trim().toUpperCase() === 'FUNDAMENTAL'
    ? 'Anexar comprovante de conclusão de ensino fundamental.'
    : degree
      ? 'Anexe aqui, comprovante de graduação.'
      : 'Anexar comprovante de conclusão de ensino médio (Apenas para os cargos que exigem)';

export const uploadReg = (): string =>
  'Anexe aqui, comprovante de inscrição no conselho de classe competente (quando aplicável)';
