import axios from 'axios';

type httpMethods = 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';

export const httpAxios = async <T, K>(
  route: string,
  method: httpMethods,
  body?: T,
) => {
  const token = window.localStorage.getItem('accessToken');

  try {
    return await axios<K>(
      `${process.env.REACT_APP_API_URL}/${route}`,
      body
        ? {
            method,
            data: body,
            headers: { Authorization: `Bearer ${token}` },
          }
        : { method, headers: { Authorization: `Bearer ${token}` } },
    );
  } catch (err) {
    throw err;
  }
};

export const httpAxiosQuestions = async <T, K>(
  route: string,
  method: httpMethods,
  body?: T,
) => {
  const token = window.localStorage.getItem('questionsToken');

  try {
    return await axios<K>(
      `${process.env.REACT_APP_API_URL}/${route}`,
      body
        ? {
            method,
            data: body,
            headers: { Authorization: `Bearer ${token}` },
          }
        : { method, headers: { Authorization: `Bearer ${token}` } },
    );
  } catch (err) {
    throw err;
  }
};
