import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Navbar from './components/Navbar/Navbar';
import RegisterModal from './components/RegisterModal/RegisterModal';
import CandidateDisapproved from './pages/CandidateDisapproved/CandidateDisapproved';
import DocUploadConfimed from './pages/DocUploadConfimed/DocUploadConfirmed';
import EditSubscribe from './pages/JobApplication/EditSubscribe';
import JobApplicationPage from './pages/JobApplication/JobApplicationPage';
import Login from './pages/Login/Login';
import AttachDocsResource from './pages/MySubscribe/AttachDocsResource';
import MySubscribe from './pages/MySubscribe/MySubscribe';
import ProcessPage from './pages/Process/ProcessPage';
import ProcessUserPage from './pages/Process/ProcessUserPage';
import ProvaHomePage from './pages/Provas/ProvaHomePage';
import QuestionsPage from './pages/Provas/QuestionsPage';
import RecoverPass from './pages/RecoverPass/RecoverPass';
import VacanciesPage from './pages/Vacancies/Vacanciespage';
import ProfilePage from './profile/ProfilePage';
import { theme } from './styles/theme';

const mainContentStyle: React.CSSProperties = {
  paddingTop: '3rem',
};

const App: React.FC = () => {
  const token = window.localStorage.getItem('accessToken');
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);

  const handleLogout = () => {
    window.localStorage.clear();
    setIsAuthenticated(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Navbar isAuthenticated={isAuthenticated} handleLogout={handleLogout} />
      <div style={mainContentStyle}>
        <Routes>
          <Route path="/" element={<ProcessPage />} />
          <Route
            path="/login"
            element={
              <Login
                setAuthenticated={(value: boolean) => {
                  setIsAuthenticated(value);
                }}
              />
            }
          />
          <Route path="/register" element={<RegisterModal />} />
          <Route path="/myprocesses" element={<ProcessUserPage />} />
          <Route path="/vacancies" element={<VacanciesPage />} />
          <Route path="/forgot-password" element={<RecoverPass />} />
          <Route path="/subscribe" element={<JobApplicationPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/mysubscribe" element={<MySubscribe />} />
          <Route path="/edit-form" element={<EditSubscribe />} />
          <Route path="/docs-uploaded" element={<DocUploadConfimed />} />
          <Route
            path="/attach-docs-resource"
            element={<AttachDocsResource />}
          />
          <Route
            path="/candidates-disapproved"
            element={<CandidateDisapproved />}
          />
          <Route path="/exam-home" element={<ProvaHomePage />} />
          <Route path="/questions" element={<QuestionsPage />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
};

export default App;
