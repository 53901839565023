import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ideias from '../../assets/ideias.png';
import logo from '../../assets/logo.png';
import ResponseModal from '../../components/ResponseModal';
import {
  BackButton,
  Button,
  Container,
  Content,
  ErrorMessage,
  FormContainer,
  ImageContainer,
  Input,
  Logo,
  Text,
  Title,
} from './RecoverPassStyle';

const RecoverPass: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    success: false,
    message: '',
  });
  const [showErrs, setShowErrs] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const isValidEmail = (email: string) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,4}\.[0-9]{1,4}\.[0-9]{1,4}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleRecoverPassword = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    if (!isValidEmail(email)) {
      setShowErrs(true);
      return;
    }
    setShowErrs(false);
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/pass-recover`,
        { email },
      );
      setModalContent({
        success: true,
        message:
          response.data.message ||
          'E-mail enviado com sucesso! Verifique sua caixa de entrada.',
      });
      setShowModal(true);
    } catch (error) {
      let message = 'Ocorreu um erro. Por favor, tente novamente.';
      if (error instanceof Error) {
        message = error.message;
      } else if (axios.isAxiosError(error)) {
        message = error.response?.data.message || message;
      }
      setModalContent({ success: false, message: message });
      setShowModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const goBack = () => {
    navigate('/');
  };

  return (
    <Container>
      <Logo src={logo} alt="FUNEV" />
      <Content>
        <ImageContainer>
          <img src={ideias} alt="Ideias" />
        </ImageContainer>
        <FormContainer>
          <Title>Digite seu e-mail</Title>
          <form onSubmit={handleRecoverPassword}>
            <Input
              type="email"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {showErrs && (
              <ErrorMessage>
                <FontAwesomeIcon icon={faExclamationCircle} />{' '}
                {'E-mail inválido!'}
              </ErrorMessage>
            )}
            <Text>
              Após solicitar a recuperação de senha, por favor, verifique sua
              caixa de entrada, spam ou lixeira para o e-mail de recuperação.
            </Text>
            <Button type="submit" disabled={isLoading}>
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                'Enviar'
              )}
            </Button>
          </form>
          <BackButton onClick={goBack}>Cancelar</BackButton>
        </FormContainer>
      </Content>
      <ResponseModal
        show={showModal}
        onClose={handleCloseModal}
        success={modalContent.success}
        message={modalContent.message}
      />
    </Container>
  );
};

export default RecoverPass;
