import {
  faCheckCircle,
  faExclamationCircle,
  faTerminal,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ResponseModal from '../../components/ResponseModal';
import { IJob } from '../../models/job.model';
import { httpAxios } from '../../utils/axios';
import DeclarationCheckboxUpload from './DeclarationCheckboxUpload';
import JobCardUpload from './JobCardUpload';
import {
  ApplicationProcess,
  ApplicationProcessCandidate,
} from './MySubscribeTypes';
import UploadDocsResources from './UploadDocsResources';
import { ErrorMessage } from './UploadForm/FormsStyles';

const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 30px auto;
  text-align: center;
`;

const SuccessIcon = styled.div`
  color: green;
  font-size: 48px;
  margin-bottom: 20px;
`;

const CodeHighlight = styled.span`
  font-weight: bold;
  color: #007bff;
`;

interface RouteState {
  applicationProcessesId?: string;
}

export interface FormErrorsUpload {
  resourceDocs?: string;
  reason?: string;
}

const AttachDocsResource: React.FC = () => {
  const location = useLocation();
  const navigator = useNavigate();
  const processesData = location.state as RouteState;
  const [registrationCode, setRegistrationCode] = useState('');
  const [processCode, setProcessCode] = useState('');
  const [applicationProcess, setApplicationProcess] =
    useState<ApplicationProcess>();
  const [job, setJob] = useState<IJob | undefined>();
  const [applicationProcessesCandidates, setApplicationProcessesCandidates] =
    useState<ApplicationProcessCandidate | undefined>();
  const [uploadFile, setUploadFile] = useState<boolean>(true);
  const [isDeclarationChecked, setIsDeclarationChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalUpload, setShowModalUpLoad] = useState(false);
  const [modalContent, setModalContent] = useState({
    success: false,
    message: '',
  });

  const [errors, setErrors] = useState<FormErrorsUpload>({});

  const [files, setFiles] = useState<{
    resourcesDocsFile?: File;
    reason?: string;
  }>({});

  const getIsUserSubscribe = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const { data } = await httpAxios<unknown, any>(
        //`application-processes/candidates/self/${processesData.applicationProcessesId}`,
        `application-processes/candidates/self/${window.localStorage.getItem(
          'applicationProcessesId',
        )}`,
        'GET',
        headers,
      );
      setApplicationProcess(data);
      setProcessCode(
        data.applicationProcessesCandidates.applicationProcessesId,
      );
      setRegistrationCode(data.applicationProcessesCandidates.id);
      setJob(data.applicationProcessesJobs[0].job);
      setApplicationProcessesCandidates(data.applicationProcessesCandidates);
    } catch (error) {
      console.error('Erro ao buscar processos de aplicação:', error);
    }
  };

  const uploadServer = async (file: File, reason: string, endpoint: string) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const formData = new FormData();
    formData.append('file', file);
    formData.append('reason', reason);

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data',
    };
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/${endpoint}/${registrationCode}`,
      formData,
      { headers },
    );
  };

  const validateDocs = () => {
    const newErrors: FormErrorsUpload = {};

    const file = files.resourcesDocsFile;
    const reason = files.reason;

    if (!file) {
      newErrors.resourceDocs = 'É obrigatório anexar o documento.';
    } else if (file.type !== 'application/pdf') {
      newErrors.resourceDocs = 'O documento deve ser do tipo PDF';
    } else if (file.size < 1024) {
      newErrors.resourceDocs = 'Documento inválido ou corrompido.';
    } else if (file.size > 15 * 1024 * 1024) {
      newErrors.resourceDocs = 'O documento excede o tamanho máximo de 15mb';
    }

    if (!reason) {
      newErrors.reason = 'O campo motivo é obrigatório.';
    } else if (reason.length < 15) {
      newErrors.reason = 'O campo motivo deve ter no mínimo 15 caracteres.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sendUploads = async () => {
    try {
      if (!isDeclarationChecked) {
        setModalContent({
          success: false,
          message: 'Precisa marcar a declaração de leitura do edital!',
        });
        setShowModal(true);
        return;
      }

      if (!validateDocs()) {
        setShowModalUpLoad(true);
        window.scrollTo(0, 0);
        return;
      }

      if (files.resourcesDocsFile)
        await uploadServer(
          files.resourcesDocsFile,
          files.reason ?? '',
          'archives/appeal',
        );

      navigator('/docs-uploaded');
    } catch (error) {
      let message =
        'Erro ao tentar conectar com servidor. Por favor, tente novamente.';
      if (axios.isAxiosError(error)) {
        message = error.response?.data.message || message;
      } else if (error instanceof Error) {
        message = error.message;
      }

      setModalContent({ success: false, message: message });
      setShowModal(true);
    }
  };

  const setAttachmentDocsResources = (file: File) => {
    const type = 'resourcesDocsFile';
    if (file) {
      setFiles((prevFiles) => ({ ...prevFiles, [type]: file }));
    }
  };
  const setReasonField = (reason: string) => {
    const type = 'reason';
    if (reason) {
      setFiles((prevFiles) => ({ ...prevFiles, [type]: reason }));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getIsUserSubscribe();
  }, []);

  useEffect(() => {
    if (!showModalUpload) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1000);
    }
  }, [showModalUpload]);

  const handleDeclarationCheck = (isChecked: boolean) => {
    setIsDeclarationChecked(isChecked);
  };

  return (
    <>
      <Container>
        <div id="pdf-container">
          <SuccessIcon>
            <FontAwesomeIcon icon={faCheckCircle} />
          </SuccessIcon>
          <h3>Anexo de documentos</h3>
          <p>
            Aos não habilitados, anexe sua documentação, comprovando todas as
            informações declaradas na fase da inscrição, para prosseguir
            concorrendo às vagas remanescentes. No caso da não comprovação
            integral, a nota será majorada, e caso seja inferior a nota do
            último candidato convocado na primeira etapa, será desclassificado.
          </p>

          <p>
            Número do processo: <CodeHighlight>{processCode}</CodeHighlight>
          </p>
          <p>
            Sua inscrição está registrada sob o código:{' '}
            <CodeHighlight>{registrationCode}</CodeHighlight>
          </p>
          {job && applicationProcessesCandidates && (
            <JobCardUpload jobDetails={job} />
          )}

          {errors.resourceDocs && (
            <ErrorMessage>
              <FontAwesomeIcon icon={faExclamationCircle} />{' '}
              {errors.resourceDocs}
            </ErrorMessage>
          )}
          {errors.reason && (
            <ErrorMessage>
              <FontAwesomeIcon icon={faExclamationCircle} /> {errors.reason}
            </ErrorMessage>
          )}

          <UploadDocsResources
            setAttachmentDocsResources={(file) =>
              setAttachmentDocsResources(file)
            }
            setReasonField={(reason) => setReasonField(reason)}
          />

          {uploadFile && (
            <>
              <DeclarationCheckboxUpload onCheck={handleDeclarationCheck} />
              <Button
                onClick={() => sendUploads()}
                variant="success"
                size="lg"
                style={{
                  marginRight: '5px',
                  marginBottom: '5px',
                }}
              >
                <FontAwesomeIcon icon={faTerminal} /> Enviar Documentação
              </Button>{' '}
            </>
          )}
        </div>
      </Container>
      <ResponseModal
        show={showModal}
        onClose={() => setShowModal(false)}
        success={modalContent.success}
        message={modalContent.message}
      />
    </>
  );
};

export default AttachDocsResource;
