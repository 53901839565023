import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

type ProgressProps = {
  answeredQuestions: string;
  timeLimit: string;
  onQuizComplete: () => void;
  onTimeUp: () => void;
};

const ProgressContainer = styled.div`
  max-width: 500px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
  background-color: #f8f9fa;
`;

const ProgressInfo = styled.p`
  font-size: 1.2rem;
  color: #007bff;
  margin-bottom: 10px;
  font-weight: 700;
`;

const TimeCounter = styled.p<{ isTimeLow: boolean }>`
  font-size: 1.1rem;
  color: ${(props) => (props.isTimeLow ? '#dc3545' : '#28a745')};
  font-weight: 700;
`;

const FinishButton = styled.button`
  margin-top: 15px;
  color: white;
  background-color: #28a745;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const CompletionMessage = styled.p`
  margin-top: 20px;
  font-size: 1rem;
  color: #28a745;
  font-weight: 700;
`;

const Progress: React.FC<ProgressProps> = ({
  answeredQuestions,
  timeLimit,
  onQuizComplete,
  onTimeUp,
}) => {
  const [timeRemaining, setTimeRemaining] = useState('00:00');
  const [isTimeLow, setIsTimeLow] = useState(false);
  const [timerEnded, setTimerEnded] = useState(false);

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = new Date();
      const limitDate = new Date(timeLimit);
      const difference = limitDate.getTime() - now.getTime();

      if (difference <= 0) {
        setTimerEnded(true);
        onTimeUp();
        return '00:00';
      }

      const diffSec = difference / 1000;
      const minutes = Math.floor(diffSec / 60);
      const seconds = 60 * (diffSec / 60 - Math.floor(diffSec / 60));

      setIsTimeLow(minutes < 10);
      return `${minutes.toFixed(0).padStart(2, '0')}:${seconds
        .toFixed(0)
        .padStart(2, '0')}`;
    };

    if (!timerEnded) {
      const timer = setInterval(() => {
        setTimeRemaining(calculateTimeRemaining());
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timeLimit, timerEnded, onTimeUp]);

  const isQuizComplete =
    answeredQuestions.split(' / ')[0] === answeredQuestions.split(' / ')[1];

  return (
    <ProgressContainer>
      <ProgressInfo>Progresso: {answeredQuestions} Respondida(s).</ProgressInfo>
      <TimeCounter isTimeLow={isTimeLow}>
        Tempo Restante: {timeRemaining}
      </TimeCounter>
      {isQuizComplete && (
        <>
          <CompletionMessage>
            Parabéns por concluir a prova antes do prazo! Por favor, revise suas
            respostas e pressione o botão "Finalizar" quando estiver pronto.
          </CompletionMessage>
          <FinishButton onClick={onQuizComplete}>Finalizar</FinishButton>
        </>
      )}
    </ProgressContainer>
  );
};

export default Progress;
