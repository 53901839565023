import {
  faCheckCircle,
  faEdit,
  faExclamationCircle,
  faPrint,
  faTerminal,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ResponseModal from '../../components/ResponseModal';
import ResponseModalErroUpload from '../../components/ResponseModalErroUpload';
import { IApplicationProcessesCandidate } from '../../models/application-process-candidate.model.js';
import { IApplicationAndProcessCandidate } from '../../models/application-process.model.js';
import { filesField, IFiles } from '../../models/archive.model';
import { IJob } from '../../models/job.model.js';
import { httpAxios } from '../../utils/axios';
import { ErrorMessage } from '../Login/LoginStyles';
import CertificationTable from './CertificationTable';
import DeclarationCheckboxUpload from './DeclarationCheckboxUpload';
import DegreeTable from './DegreeTable';
import InternshipExperienceTable from './InternshipExperienceTable';
import JobCardUpload from './JobCardUpload';
import UploadAddress from './UploadAddress';
import WorkExperienceTable from './WorkExperienceTable';

const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 30px auto;
  text-align: center;
`;

const SuccessIcon = styled.div`
  color: green;
  font-size: 48px;
  margin-bottom: 20px;
`;

const CodeHighlight = styled.span`
  font-weight: bold;
  color: #007bff;
`;

interface RouteState {
  applicationProcessesId?: string;
}

export interface FormErrorsUpload {
  certificationFile?: string;
  degreeFile?: string;
  internshipExperienceFile?: string;
  workExperienceFile?: string;
  addressFile?: string;
  highSchoolFile?: string;
  bachelorFile?: string;
  elementaryFile?: string;
  regFile?: string;
  identityFile?: string;
}

const MySubscribe: React.FC = () => {
  const navigator = useNavigate();
  const [registrationCode, setRegistrationCode] = useState('');
  const [processCode, setProcessCode] = useState('');
  const [applicationProcess, setApplicationProcess] =
    useState<IApplicationAndProcessCandidate>();
  const [job, setJob] = useState<IJob | undefined>();
  const [applicationProcessesCandidates, setApplicationProcessesCandidates] =
    useState<IApplicationProcessesCandidate | undefined>();
  const [uploadFile, setUploadFile] = useState<boolean>(false);
  const [isDeclarationChecked, setIsDeclarationChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalUpload, setShowModalUpLoad] = useState(false);
  const [modalContent, setModalContent] = useState({
    success: false,
    message: '',
  });

  const [errors, setErrors] = useState<FormErrorsUpload>({});

  const [files, setFiles] = useState<IFiles>({});

  const generatePdf = () => {
    const input = document.getElementById('pdf-container');
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'px',
          format: [canvas.width, canvas.height],
        });

        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
        pdf.save(`ficha_de_inscricao_[${registrationCode}].pdf`);
      });
    }
  };

  const getIsUserSubscribe = async () => {
    try {
      const { data } = await httpAxios<
        unknown,
        IApplicationAndProcessCandidate
      >(
        `application-processes/candidates/self/${window.localStorage.getItem(
          'applicationProcessesId',
        )}`,
        'GET',
      );
      setApplicationProcess(data);
      setProcessCode(
        data.applicationProcessesCandidates.applicationProcessesId,
      );
      setRegistrationCode(data.applicationProcessesCandidates.id);
      setJob(data.applicationProcessesJobs[0].job);
      setApplicationProcessesCandidates(data.applicationProcessesCandidates);
      setUploadFile(data.applicationProcessesCandidates.level === 2);
    } catch (error) {
      console.error('Erro ao buscar processos de aplicação:', error);
    }
  };

  const uploadServer = async (files: IFiles, endpoint: string) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const formData = new FormData();

    const fields = Object.keys(files) as Array<keyof IFiles>;

    for (const field of fields) {
      if (!files[field]) {
        continue;
      }

      formData.append(field, files[field] as File);
    }

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data',
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/${endpoint}/${registrationCode}`,
      formData,
      { headers },
    );
  };

  const validateDocs = () => {
    const newErrors: FormErrorsUpload = {};
    if (!files.identity) {
      newErrors.identityFile =
        'Precisa anexar o comprovante de identificação (CPF)';
    }

    if (job?.degree && !files.bachelor) {
      newErrors.bachelorFile = `Precisa anexar comprovante de graduação`;
    }

    if (job?.jobType === 'Médio' && !files.highSchool) {
      newErrors.highSchoolFile = `Precisa anexar comprovante de conclusão de ensino médio`;
    }

    if (job?.jobType === 'Fundamental' && !files.elementary) {
      newErrors.elementaryFile = `Precisa anexar comprovante de conclusão de ensino fundamental`;
    }

    if (!files.certification && applicationProcess?.certifications.length) {
      newErrors.certificationFile = `Precisa anexar ${
        job?.degree
          ? 'comprovantes do(s) curso(s) de extensão e/ou complementares na área de atuação.'
          : 'comprovantes do(s) curso(s) complementares na área de atuação'
      }`;
    }

    if (!files.degrees && applicationProcess?.degress.length) {
      newErrors.degreeFile = `Precisa anexar ${
        job?.degree
          ? 'comprovantes do(s) curso(s) de Pós-Graduação, Mestrado e/ou Doutorado'
          : 'comprovantes do(s) curso(s) de Pós-Graduação, Mestrado e/ou Doutorado'
      }`;
    }

    if (!files.internshipExps && applicationProcess?.internshipExps.length) {
      newErrors.internshipExperienceFile = `Precisa anexar ${
        job?.degree
          ? 'comprovantes de experiência de voluntariado, ou de estágio não obrigatório'
          : 'comprovantes de experiência de voluntariado, ou de estágio não obrigatório'
      }`;
    }

    if (!files.workExps && applicationProcess?.workExps.length) {
      newErrors.workExperienceFile = `Precisa anexar ${
        job?.degree
          ? 'comprovantes de experiência profissional.'
          : 'comprovantes de experiência profissional.'
      }`;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sendUploads = async () => {
    try {
      if (!isDeclarationChecked) {
        setModalContent({
          success: false,
          message: 'Precisa marcar a declaração de leitura do edital',
        });
        setShowModal(true);
        return;
      }

      if (!validateDocs()) {
        setShowModalUpLoad(true);
        return;
      }

      await uploadServer(files, 'archives');

      navigator('/docs-uploaded');
    } catch (error) {
      let message =
        'Erro ao tentar conectar com servidor. Por favor, tente novamente.';
      if (axios.isAxiosError(error)) {
        message = error.response?.data.message || message;
      } else if (error instanceof Error) {
        message = error.message;
      }

      setModalContent({ success: false, message: message });
      setShowModal(true);
    }
  };

  const setFile = (file: File, type: filesField) => {
    if (file) {
      setFiles((prevFiles) => ({ ...prevFiles, [type]: file }));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getIsUserSubscribe();
  }, []);

  useEffect(() => {
    if (!showModalUpload) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1000);
    }
  }, [showModalUpload]);

  const openEditForm = () => {
    navigator('/edit-form');
  };

  const handleDeclarationCheck = (isChecked: boolean) => {
    setIsDeclarationChecked(isChecked);
  };

  return (
    <>
      <Container>
        <div id="pdf-container">
          <SuccessIcon>
            <FontAwesomeIcon icon={faCheckCircle} />
          </SuccessIcon>
          {uploadFile ? (
            <>
              <h3>
                Declaração realizada com sucesso, agora anexe a documentação
                segundo o cadastro realizado!
              </h3>{' '}
            </>
          ) : (
            <>
              {' '}
              <h3>Sua inscrição foi realizada com sucesso!</h3>
              <p>Agora, aguarde o anúncio da próxima fase.</p>
            </>
          )}
          <p>
            Número do processo: <CodeHighlight>{processCode}</CodeHighlight>
          </p>
          <p>
            Sua inscrição está registrada sob o código:{' '}
            <CodeHighlight>{registrationCode}</CodeHighlight>
          </p>

          {job && applicationProcessesCandidates && (
            <JobCardUpload jobDetails={job} />
          )}

          <Button
            onClick={() => generatePdf()}
            variant="outline-primary"
            size="lg"
            style={{ marginRight: '5px', marginBottom: '5px' }}
          >
            <FontAwesomeIcon icon={faPrint} /> Imprimir
          </Button>
          {applicationProcess?.levels === 1 && (
            <Button
              onClick={() => openEditForm()}
              variant="danger"
              size="lg"
              style={{ marginRight: '5px', marginBottom: '5px' }}
            >
              <FontAwesomeIcon icon={faEdit} /> Editar Inscrição
            </Button>
          )}

          <CertificationTable
            certifications={applicationProcess?.certifications}
            degree={job?.degree}
            setAttachment={(file) => setFile(file, 'certification')}
            uploadFile={uploadFile}
          />

          {job?.degree && (
            <DegreeTable
              degress={applicationProcess?.degress}
              setAttachment={(file) => setFile(file, 'degrees')}
              uploadFile={uploadFile}
            />
          )}
          <InternshipExperienceTable
            internships={applicationProcess?.internshipExps}
            setAttachment={(file) => setFile(file, 'internshipExps')}
            uploadFile={uploadFile}
          />
          <WorkExperienceTable
            experiences={applicationProcess?.workExps}
            setAttachment={(file) => setFile(file, 'workExps')}
            uploadFile={uploadFile}
          />
        </div>

        <div>
          {applicationProcess?.applicationProcessesCandidates?.level === 2 ? (
            <>
              {errors.identityFile && (
                <ErrorMessage>
                  <FontAwesomeIcon icon={faExclamationCircle} />{' '}
                  {errors.identityFile}
                </ErrorMessage>
              )}
              {errors.addressFile && (
                <ErrorMessage>
                  <FontAwesomeIcon icon={faExclamationCircle} />{' '}
                  {errors.addressFile}
                </ErrorMessage>
              )}
              {errors.bachelorFile && (
                <ErrorMessage>
                  <FontAwesomeIcon icon={faExclamationCircle} />{' '}
                  {errors.bachelorFile}
                </ErrorMessage>
              )}
              {errors.highSchoolFile && (
                <ErrorMessage>
                  <FontAwesomeIcon icon={faExclamationCircle} />{' '}
                  {errors.highSchoolFile}
                </ErrorMessage>
              )}
              {errors.elementaryFile && (
                <ErrorMessage>
                  <FontAwesomeIcon icon={faExclamationCircle} />{' '}
                  {errors.elementaryFile}
                </ErrorMessage>
              )}

              {errors.certificationFile && (
                <ErrorMessage>
                  <FontAwesomeIcon icon={faExclamationCircle} />{' '}
                  {errors.certificationFile}
                </ErrorMessage>
              )}
              {errors.degreeFile && (
                <ErrorMessage>
                  <FontAwesomeIcon icon={faExclamationCircle} />{' '}
                  {errors.degreeFile}
                </ErrorMessage>
              )}
              {errors.internshipExperienceFile && (
                <ErrorMessage>
                  <FontAwesomeIcon icon={faExclamationCircle} />{' '}
                  {errors.internshipExperienceFile}
                </ErrorMessage>
              )}
              {errors.workExperienceFile && (
                <ErrorMessage>
                  <FontAwesomeIcon icon={faExclamationCircle} />{' '}
                  {errors.workExperienceFile}
                </ErrorMessage>
              )}

              <UploadAddress
                degree={job?.degree}
                register={job?.register}
                jobType={job?.jobType ?? ''}
                setAttachmentIdentity={(file) => setFile(file, 'identity')}
                setAttachmentAddress={(file) => setFile(file, 'address')}
                setAttachmentReg={(file) => setFile(file, 'reg')}
                setAttachmentSchool={(file) =>
                  setFile(
                    file,
                    job?.degree
                      ? 'bachelor'
                      : job?.jobType === 'Fundamental'
                        ? 'elementary'
                        : 'highSchool',
                  )
                }
              />

              {uploadFile && (
                <>
                  <DeclarationCheckboxUpload onCheck={handleDeclarationCheck} />
                  <Button
                    onClick={() => sendUploads()}
                    variant="success"
                    size="lg"
                    style={{
                      marginRight: '5px',
                      marginBottom: '5px',
                    }}
                  >
                    <FontAwesomeIcon icon={faTerminal} /> Enviar Documentação
                  </Button>{' '}
                </>
              )}
            </>
          ) : (
            ''
          )}
        </div>
      </Container>
      <ResponseModal
        show={showModal}
        onClose={() => setShowModal(false)}
        success={modalContent.success}
        message={modalContent.message}
      />
      <ResponseModalErroUpload
        show={showModalUpload}
        onClose={() => setShowModalUpLoad(false)}
        errors={errors}
      />
    </>
  );
};

export default MySubscribe;
