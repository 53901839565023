import {
  faHome,
  faSignInAlt,
  faSignOutAlt,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { linkStyle, navItem, navItems, navStyle } from './NavbarStyle';
import { Email } from './NavbarStyleComp';

interface INavbar {
  isAuthenticated: boolean;
  handleLogout: () => void;
}

const Navbar = ({ isAuthenticated, handleLogout }: INavbar) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isLoginRoute = location.pathname === '/login';
  const isRegisterRoute = location.pathname === '/register';

  const handlePrepareLogount = () => {
    navigate('/');
    handleLogout();
  };

  return (
    <nav style={navStyle}>
      <div style={navItems}>
        <Link to="/" style={linkStyle}>
          <div style={navItem}>
            <FontAwesomeIcon icon={faHome} />
            <div>Início</div>
          </div>
        </Link>
      </div>
      <div style={navItems}>
        {isAuthenticated ? (
          <>
            <div>
              <span style={linkStyle} onClick={() => navigate('/register')}>
                <div style={navItem}>
                  <FontAwesomeIcon icon={faUser} />
                  <Email>{window.localStorage.getItem('userName')}</Email>
                </div>
              </span>
            </div>
            <div>
              <span onClick={handlePrepareLogount} style={linkStyle}>
                <div style={navItem}>
                  <FontAwesomeIcon icon={faSignOutAlt} />
                  <div>Sair</div>
                </div>
              </span>
            </div>
          </>
        ) : (
          <>
            <div>
              {isAuthenticated || isRegisterRoute ? (
                <></>
              ) : (
                <Link to="/register" style={linkStyle}>
                  <div style={navItem}>
                    <FontAwesomeIcon icon={faUser} />
                    <div>Registrar</div>
                  </div>
                </Link>
              )}
            </div>
            <div>
              {isAuthenticated || isLoginRoute ? (
                <></>
              ) : (
                <Link to="/login" style={linkStyle}>
                  <div style={navItem}>
                    <FontAwesomeIcon icon={faSignInAlt} />
                    <div>Acessar</div>
                  </div>
                </Link>
              )}
            </div>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
