import React from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: flex;
`;

const StyledCheckbox = styled.input`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

interface DeclarationCheckboxProps {
  onCheck: (isChecked: boolean) => void;
}

const DeclarationCheckbox: React.FC<DeclarationCheckboxProps> = ({
  onCheck,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onCheck(e.target.checked);
  };

  return (
    <CheckboxContainer>
      <StyledCheckbox
        type="checkbox"
        id="declarationCheckbox"
        onChange={handleChange}
      />
      <Label htmlFor="declarationCheckbox">
        Declaro que li o edital, estou ciente e de acordo com todas as regras.
        Declaro ainda que todas as informaçõe auto declaratórias acima são
        verdadeiras, e que as documentações quem comprovam as informações serão
        entregues na segunda etapa, na forma do edital, estando ciente de que
        conforme item 2.6 e 2.7, qualquer divergência, insuficiência, ou
        inobserância das regras, implicará na minha eliminação do presente
        edital, não cabendo posterior correção.
      </Label>
    </CheckboxContainer>
  );
};

export default DeclarationCheckbox;
