import axios from 'axios';
import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import ResponseModal from '../../components/ResponseModal';
import { httpAxios } from '../../utils/axios';
import { ApplicationProcesses, Job } from '../Process/InterfacesProcess';
import {
  BtnBack,
  LinkButton,
  ProcessPageContainer,
  TableCard,
  TableHeader,
  Title,
} from './VacanciasStyle';

interface RouteState {
  applicationProcesses?: ApplicationProcesses;
}

const VacanciesPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const processesData = location.state as RouteState;
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    success: false,
    message: '',
  });

  const getIsUserSubscribe = async (
    process: ApplicationProcesses,
    job: Job,
  ) => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const { data } = await httpAxios<unknown, any>(
        `application-processes/candidates/self/${processesData.applicationProcesses?.id}`,
        'GET',
        headers,
      );
      window.localStorage.setItem('applicationProcessesId', process.id);
      navigate('/mysubscribe');
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response &&
        error.response.status === 404
      ) {
        navigate('/subscribe', {
          state: { applicationProcesses: process, job },
        });
      } else {
        console.error('Erro ao buscar processos de aplicação:', error);
        let message =
          'Erro ao tentar conectar com servidor. Por favor, tente novamente.';
        if (axios.isAxiosError(error)) {
          message = error.response?.data.message || message;
        } else if (error instanceof Error) {
          message = error.message;
        }
        setModalContent({ success: false, message: message });
        setShowModal(true);
      }
    }
  };

  const handleVacanciesClick = (process: ApplicationProcesses, job: Job) => {
    const accessToken = window.localStorage.getItem('accessToken');
    if (accessToken) {
      getIsUserSubscribe(process, job);
    } else {
      navigate('/login');
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <ProcessPageContainer>
      <BtnBack onClick={handleBack}>Voltar</BtnBack>
      <Title>
        Lista de Vagas do Edital {processesData.applicationProcesses?.code}
      </Title>

      <TableCard>
        <TableCard.Body>
          <Table striped bordered hover>
            <TableHeader>
              <tr>
                <th>Descrição</th>
                <th>Nível</th>
                <th>Ações</th>
              </tr>
            </TableHeader>
            <tbody>
              {processesData.applicationProcesses &&
                processesData.applicationProcesses!.applicationProcessesJobs.map(
                  (process, index) => (
                    <tr key={index}>
                      <td>{process.job.name}</td>
                      <td>{process.job.jobType}</td>
                      <td>
                        {processesData.applicationProcesses?.status ===
                        'Aberto' ? (
                          <LinkButton
                            onClick={() =>
                              handleVacanciesClick(
                                processesData.applicationProcesses!,
                                process.job,
                              )
                            }
                            buttonType="vagas"
                          >
                            Inscrever
                          </LinkButton>
                        ) : processesData.applicationProcesses?.status ===
                          'Agendado' ? (
                          <LinkButton buttonType="agendado">
                            Agendado
                          </LinkButton>
                        ) : (
                          <LinkButton buttonType="encerrado">
                            Inscrições Encerradas
                          </LinkButton>
                        )}
                      </td>
                    </tr>
                  ),
                )}
            </tbody>
          </Table>
        </TableCard.Body>
      </TableCard>
      <ResponseModal
        show={showModal}
        onClose={() => setShowModal(false)}
        success={modalContent.success}
        message={modalContent.message}
      />
    </ProcessPageContainer>
  );
};

export default VacanciesPage;
