import { IDegreeOptions } from '../../models/custom.model';

export const formationCourseInstrutions = (degree: boolean): string =>
  degree
    ? '1. Declare, caso possua, o(s) curso(s) de extensão e/ou complementares na área de atuação'
    : '1. Declare, caso possua, o(s) curso(s) complementares na área de atuação';

export const DegreeInstrutions = (degree: boolean): string =>
  degree
    ? '2. Declare, caso possua o(s) curso(s) de Pós-Graduação, Mestrado e/ou Doutorado em áreas afins do cargo pretendido com carga horária mínima válida conforme legislação vigente.'
    : '';

export const ExperiencesInstrutions = (degree: boolean): string =>
  degree
    ? '3. Declare, caso possua, sua experiência de voluntariado, ou de estágio não obrigatório na área pretendida. Preencher quantos campos for necessário, se for necessário apenas 1 campo, preencha apenas 1.'
    : '2. Declare, caso possua, sua experiência de voluntariado, ou de estágio não obrigatório na área pretendida. Preencher quantos campos for necessário, se for necessário apenas 1 campo, preencha apenas 1.';
export const ExperiencesProInstrutions = (degree: boolean): string =>
  degree
    ? '4. Declare, caso possua, sua experiência profissional na área pretendida. Preencher quantos campos for necessário, se for necessário apenas 1 campo, preencha apenas 1.'
    : '3. Declare, caso possua, sua experiência profissional na área pretendida. Preencher quantos campos for necessário, se for necessário apenas 1 campo, preencha apenas 1.';

export const GraduationInstrutions = (): string =>
  ' Declare, seu nível de escolaridade.';

export const degreeOptions: IDegreeOptions[] = [
  {
    id: 'jry9h5vua0el9dzlnn4fy7j6',
    name: 'Pós-Graduação',
  },
  {
    id: 'vi5n29tt7t9yib0om73vmhxx',
    name: 'Mestrado',
  },
  {
    id: 'nvhfyy7wai5ybak2a2pzw2xt',
    name: 'Doutorado',
  },
];
