import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import JobCardUpload from '../MySubscribe/JobCardUpload';
import {
  ApplicationProcess,
  ApplicationProcessCandidate,
} from '../MySubscribe/MySubscribeTypes';
import { IJob } from '../../models/job.model';
import { httpAxios } from '../../utils/axios';

const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 30px auto;
  text-align: center;
`;

const SuccessIcon = styled.div`
  color: green;
  font-size: 48px;
  margin-bottom: 20px;
`;

const CodeHighlight = styled.span`
  font-weight: bold;
  color: #007bff;
`;

const DocUploadConfirmed: React.FC = () => {
  const [registrationCode, setRegistrationCode] = useState('');
  const [processCode, setProcessCode] = useState('');
  const [job, setJob] = useState<IJob | undefined>();
  const [applicationProcessesCandidates, setApplicationProcessesCandidates] =
    useState<ApplicationProcessCandidate | undefined>();
  useState<ApplicationProcess>();

  const getIsUserSubscribe = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const { data } = await httpAxios<unknown, any>(
        `application-processes/candidates/self/${window.localStorage.getItem(
          'applicationProcessesId',
        )}`,
        'GET',
        headers,
      );
      setProcessCode(
        data.applicationProcessesCandidates.applicationProcessesId,
      );
      setRegistrationCode(data.applicationProcessesCandidates.id);
      setJob(data.applicationProcessesJobs[0].job);
      setApplicationProcessesCandidates(data.applicationProcessesCandidates);
    } catch (error) {
      console.error('Erro ao buscar processos de aplicação:', error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getIsUserSubscribe();
  }, []);

  return (
    <>
      <Container>
        <SuccessIcon>
          <FontAwesomeIcon icon={faCheckCircle} />
        </SuccessIcon>
        <h3>Parabéns documentos anexados com sucesso!</h3>
        <p>Agora, aguarde a próxima fase.</p>
        <p>
          Número do processo: <CodeHighlight>{processCode}</CodeHighlight>
        </p>
        <p>
          Sua inscrição está registrada sob o código:{' '}
          <CodeHighlight>{registrationCode}</CodeHighlight>
        </p>
        {job && applicationProcessesCandidates && (
          <JobCardUpload jobDetails={job} />
        )}
      </Container>
    </>
  );
};

export default DocUploadConfirmed;
