import {
  faExclamationCircle,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Form, FormControl, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png';
import ResponseModal from '../../components/ResponseModal';
import { marginBottom } from '../../styles/custom';
import { httpAxios } from '../../utils/axios';
import {
  BackButton,
  Button,
  Container,
  ErrorMessage,
  EyeIcon,
  Input,
  InputContainer,
  Logo,
  PageContainer,
  StyledInputGroup,
  Title,
} from './RegisterModalStyles';
import useRegisterForm from './useRegisterForm';

const RegisterModal: React.FC = () => {
  const navigate = useNavigate();
  const {
    formState,
    setFormState,
    handleChange,
    handleSubmit,
    showModal,
    handleCloseModal,
    modalContent,
    errors,
    isLoading,
  } = useRegisterForm();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const goBack = () => {
    navigate('/');
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const user = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user') ?? '')
      : undefined;

    if (!user) {
      return;
    }

    try {
      const { data } = await httpAxios<any, any>(
        'candidates/' + user.candidate.id,
        'GET',
      );

      setFormState((prevState: any) => ({
        ...prevState,
        id: data.id,
        nome: data.name,
        cpf: data.cpf,
        email: data.email,
        telefone: data.phone,
        birthDate: data.birthdate,
        pcd: data.pcd,
      }));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <PageContainer>
      <Logo src={logo} alt="FUNEV" />
      <Container>
        <Title>Cadastro de talento</Title>

        <Input
          name="nome"
          value={formState.nome}
          onChange={handleChange}
          placeholder="Nome"
        />
        {errors.nome && (
          <ErrorMessage>
            <FontAwesomeIcon icon={faExclamationCircle} /> {errors.nome}
          </ErrorMessage>
        )}

        <Input
          name="cpf"
          value={formState.cpf}
          onChange={handleChange}
          placeholder="CPF"
          type="number"
        />
        {errors.cpf && (
          <ErrorMessage>
            <FontAwesomeIcon icon={faExclamationCircle} /> {errors.cpf}
          </ErrorMessage>
        )}

        <Input
          name="email"
          value={formState.email}
          onChange={handleChange}
          placeholder="E-mail"
          type="email"
        />
        {errors.email && (
          <ErrorMessage>
            <FontAwesomeIcon icon={faExclamationCircle} /> {errors.email}
          </ErrorMessage>
        )}

        <Input
          name="telefone"
          value={formState.telefone}
          onChange={handleChange}
          placeholder="Telefone Celular"
          type="number"
        />
        {errors.telefone && (
          <ErrorMessage>
            <FontAwesomeIcon icon={faExclamationCircle} /> {errors.telefone}
          </ErrorMessage>
        )}
        <label>Data de Nascimento</label>
        <StyledInputGroup className="mb-3">
          <FormControl
            type="date"
            placeholder="Data de Nascimento"
            value={formState.birthDate}
            onChange={(e) => {
              setFormState((prevState) => ({
                ...prevState,
                birthDate: e.target.value,
              }));
            }}
          />
        </StyledInputGroup>
        {errors.birthDate && (
          <ErrorMessage>
            <FontAwesomeIcon icon={faExclamationCircle} /> {errors.birthDate}
          </ErrorMessage>
        )}

        <InputContainer>
          <Input
            type={showPassword ? 'text' : 'password'}
            name="senha"
            value={formState.senha}
            onChange={handleChange}
            placeholder="Senha"
          />
          <EyeIcon onClick={togglePasswordVisibility}>
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </EyeIcon>
        </InputContainer>
        {errors.senha && (
          <ErrorMessage>
            <FontAwesomeIcon icon={faExclamationCircle} /> {errors.senha}
          </ErrorMessage>
        )}

        <InputContainer>
          <Input
            type={showPassword2 ? 'text' : 'password'}
            name="confirmSenha"
            value={formState.confirmSenha}
            onChange={handleChange}
            placeholder="Repetir a senha"
          />
          <EyeIcon onClick={togglePasswordVisibility2}>
            <FontAwesomeIcon icon={showPassword2 ? faEyeSlash : faEye} />
          </EyeIcon>
        </InputContainer>
        {errors.confirmSenha && (
          <ErrorMessage>
            <FontAwesomeIcon icon={faExclamationCircle} /> {errors.confirmSenha}
          </ErrorMessage>
        )}

        <Form style={marginBottom}>
          <Form.Check
            type="switch"
            id="isPCD"
            label="PCD"
            checked={formState.pcd}
            onChange={() => {
              setFormState((prevState) => ({
                ...prevState,
                pcd: !prevState.pcd,
              }));
            }}
          />
        </Form>

        <Button onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : formState.id ? (
            'Salvar'
          ) : (
            'Registrar'
          )}
        </Button>
        <BackButton onClick={goBack}>Cancelar</BackButton>
      </Container>
      <ResponseModal
        show={showModal}
        onClose={handleCloseModal}
        success={modalContent.success}
        message={modalContent.message}
      />
    </PageContainer>
  );
};

export default RegisterModal;
