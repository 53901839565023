export const navStyle: React.CSSProperties = {
  width: '100%',
  padding: '0.6rem 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#01008c',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1000,
};

export const navItems: React.CSSProperties = {
  margin: '0 1rem',
  display: 'flex',
  alignItems: 'center',
  gap: '2rem',
};

export const linkStyle: React.CSSProperties = {
  color: 'white',
  textDecoration: 'none',
  cursor: 'pointer',
};

export const navItem: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '0.2rem',
  fontWeight: 'bold',
};
