import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  line-height: 1.5;
  font-size: 16px;
`;

const TextAreaContainer = styled.div`
  margin-top: 15px;
`;

interface TextAreaFieldProps {
  value: string;
  onChange: (value: string) => void;
}

const TextAreaField: React.FC<TextAreaFieldProps> = ({ value, onChange }) => {
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  return (
    <TextAreaContainer>
      <label htmlFor="difficultyInfo">
        Informe a dificuldade ou informações do anexo acima
      </label>
      <StyledTextArea
        id="difficultyInfo"
        rows={3}
        value={value}
        onChange={handleChange}
      />
    </TextAreaContainer>
  );
};

export default TextAreaField;
