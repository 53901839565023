export const centeredItem: React.CSSProperties = {
  margin: 'auto',
  textAlign: 'center',
};

export const marginTop: React.CSSProperties = {
  marginTop: '1.2rem',
};

export const marginBottom: React.CSSProperties = {
  marginBottom: '0.6rem',
};

export const flexRow: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  gap: '0.6rem',
};
