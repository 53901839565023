import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ApplicationProcesses, Job } from '../Process/InterfacesProcess';
import ResponseModal from '../../components/ResponseModal';
import {
  IApplicationBody,
  IApplicationResponse,
  ICertification,
  IDegree,
  IExperience,
  iGraduationData,
} from '../../models/application-process.model';
import { flexRow } from '../../styles/custom';
import { httpAxios } from '../../utils/axios';
import { boxbtn, btn, btnback } from './JobApplicationStyle';
import ExperienceForm from './components/ExperienceComp/ExperienceForm';
import DeclarationCheckbox from './components/DeclarationCheckbox';
import GraduationForm, {
  FormErrorsGraduation,
} from './components/GraduationComp/GraduationForm';

import FormationCourseForm from './components/FormationCourseForm/FormationCourseForm';
import ExperienceProForm from './components/ExperienceProComp/ExperienceProForm';
import DegreeForm from './components/DegreeForm/DegreeForm';

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: ${(props) => props.theme.background};

  @media (max-width: 768px) {
    max-width: 380px;
  }
`;

interface RouteState {
  applicationProcesses?: ApplicationProcesses;
  job: Job;
}

const JobApplicationPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const processesData = location.state as RouteState;
  const [isDeclarationChecked, setIsDeclarationChecked] = useState(false);
  const [graduationData, setGraduationData] = useState<iGraduationData>({
    institution: '',
    modality: '',
    yearsEnd: '',
    numRegister: '',
  });
  const [errorsGraduation, setErrorsGraduation] =
    useState<FormErrorsGraduation>({});
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    success: false,
    message: '',
  });

  const [experiencesFromForm, setExperiencesFromForm] = useState<IExperience[]>(
    [],
  );
  const [jobExperiencesFromForm, setJobExperiencesFromForm] = useState<
    IExperience[]
  >([]);
  const [volunteersFromForm, setVolunteersFromForm] = useState<
    ICertification[]
  >([]);
  const [degreeeFromForm, setDegreeFromForm] = useState<IDegree[]>([]);

  const validateYear = (year: string) => {
    const isFourDigits = /^\d{4}$/.test(year);
    const isGreaterThan1900 = parseInt(year, 10) > 1900;
    return isFourDigits && isGreaterThan1900;
  };

  const validateGraduation = () => {
    const newErrors: FormErrorsGraduation = {};
    const isNumRegister = processesData.job.register
      ? !graduationData?.numRegister
      : false;
    const isValidRegister = processesData.job.register;

    if (!isValidRegister) {
      return true;
    }

    if (!graduationData.institution) {
      newErrors.institution = 'Instituição é obrigatório.';
    }
    if (!graduationData.modality) {
      newErrors.modality = 'Modalidade é obrigatório.';
    }
    if (!graduationData.yearsEnd) {
      newErrors.yearsEnd = 'Ano de conclusão é obrigatório.';
    } else if (!validateYear(graduationData.yearsEnd)) {
      newErrors.yearsEnd = 'O ano deve ter 4 dígitos e ser maior que 1900.';
    }
    if (isNumRegister) {
      if (!graduationData.numRegister) {
        newErrors.numRegister = 'Número do conselho é obrigatório.';
      }
    }

    setErrorsGraduation(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateGraduation2 = () => {
    const isNumRegister = processesData.job.register
      ? !graduationData?.numRegister
      : false;
    const isValidInputs =
      processesData.job.register || processesData.job.degree
        ? !graduationData?.institution ||
          graduationData.modality ||
          graduationData.yearsEnd
        : false;
    if (isValidInputs || isNumRegister) {
      setModalContent({
        success: false,
        message: 'Os campos de graduação incompletos!',
      });
      setShowModal(true);
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateGraduation()) {
      window.scrollTo(0, 0);
      return;
    }

    // if (!isDeclarationChecked) {
    //   setModalContent({
    //     success: false,
    //     message: 'Precisa marcar a declaração de leitura do edital!',
    //   });
    //   setShowModal(true);
    //   return;
    // }

    const requiredBody: IApplicationBody = {
      jobId: processesData.job.id,
      certifications: volunteersFromForm,
      workExps: jobExperiencesFromForm,
      internshipExps: experiencesFromForm,
    };

    if (processesData.job.register) {
      requiredBody.register = graduationData?.numRegister ?? '';
    }

    if (processesData.job.degree || processesData.job.register) {
      requiredBody.course = graduationData?.modality ?? '';
    }

    requiredBody.institution = graduationData?.institution ?? '';
    requiredBody.endDate = graduationData?.yearsEnd ?? '';

    try {
      const { data } = await httpAxios<IApplicationBody, IApplicationResponse>(
        `application-processes/register/${processesData.applicationProcesses?.id}`,
        'POST',
        processesData.job.degree
          ? { ...requiredBody, degrees: degreeeFromForm }
          : requiredBody,
      );
      window.localStorage.setItem(
        'applicationProcessesId',
        processesData.applicationProcesses?.id ?? '',
      );
      navigate('/mysubscribe');
      //setModalContent({ success: true, message: `Registro realizado com sucesso, chave de inscrição ${data.applicationProcessesId}` });
      //setShowModal(true);
    } catch (error) {
      let message =
        'Erro ao tentar conectar com servidor. Por favor, tente novamente.';
      if (axios.isAxiosError(error)) {
        message = error.response?.data.message || message;
      } else if (error instanceof Error) {
        message = error.message;
      }

      setModalContent({ success: false, message: message });
      setShowModal(true);
    }
  };

  const handleGraduationDataChange = useCallback((newData: iGraduationData) => {
    setGraduationData((prevData) => ({ ...prevData, ...newData }));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <PageContainer>
      <div style={flexRow}>
        <div>
          {' '}
          <button onClick={handleBack} style={btnback}>
            Voltar
          </button>
        </div>
        <div>
          <strong>Nome do Cargo</strong>: {processesData.job.name}
        </div>
        <div>
          <strong>Graduação</strong>: {processesData.job.degree ? 'SIM' : 'NÃO'}
        </div>
      </div>
      {/*Graduation*/}
      <GraduationForm
        errors={errorsGraduation}
        graduation={graduationData}
        onGraduationDataChange={handleGraduationDataChange}
        isRegisterNumber={processesData.job.register}
        isDegree={processesData.job.degree}
      />

      <FormationCourseForm
        degree={processesData.job.degree}
        setItens={(value) => setVolunteersFromForm(value)}
        itens={volunteersFromForm}
      />

      {processesData.job.degree && (
        <DegreeForm
          degree={processesData.job.degree}
          setDegrees={(value) => setDegreeFromForm(value)}
          Degrees={degreeeFromForm}
        />
      )}

      <ExperienceForm
        degree={processesData.job.degree}
        setExperiences={(value) => setExperiencesFromForm(value)}
        experiences={experiencesFromForm}
      />

      <ExperienceProForm
        degree={processesData.job.degree}
        setExperiences={(value) => setJobExperiencesFromForm(value)}
        experiences={jobExperiencesFromForm}
      />

      {/*declaração botão enviar*/}
      {/* <DeclarationCheckbox onCheck={handleDeclarationCheck} /> */}
      <div style={boxbtn}>
        {' '}
        <button onClick={handleSubmit} style={btn}>
          Enviar
        </button>
      </div>
      <ResponseModal
        show={showModal}
        onClose={() => setShowModal(false)}
        success={modalContent.success}
        message={modalContent.message}
      />
    </PageContainer>
  );
};

export default JobApplicationPage;
