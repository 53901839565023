import React from 'react';
import { StyledCardScreen } from './MySubscribeStyle';
import { Container } from 'react-bootstrap';
import TitleInstructions from '../JobApplication/components/TitleInstructions';
import { uploadDocsResources } from './instructionListScreen';
import UploadForm from './UploadForm/UploadForm';
import TextAreaField from './TextAreaField';
import { styled } from 'styled-components';

interface DocsResourcesProps {
  setAttachmentDocsResources: (file: File) => void;
  setReasonField: (reason: string) => void;
}

const InfoDestaque = styled.p`
  color: #495057;
  font-size: 1rem;
  margin-top: 20px;
  font-weight: 600;
`;

const UploadDocsResources: React.FC<DocsResourcesProps> = ({
  setAttachmentDocsResources,
  setReasonField,
}) => {
  const [uploadReasonField, setReasonFieldLocal] = React.useState<string>('');

  const setUploadedDocsResources = (file: File) => {
    setAttachmentDocsResources(file);
  };
  const setUploadReasonField = (reason: string) => {
    setReasonField(reason);
    setReasonFieldLocal(reason);
  };

  return (
    <StyledCardScreen>
      <Container>
        <TitleInstructions info={uploadDocsResources()} />
        <InfoDestaque>Arquivo PDF único.</InfoDestaque>
        <UploadForm
          setUploadedFile={(file: File) => setUploadedDocsResources(file)}
          degree={false}
        />

        <TextAreaField
          value={uploadReasonField}
          onChange={(reason: string) => setUploadReasonField(reason)}
        />
      </Container>
    </StyledCardScreen>
  );
};

export default UploadDocsResources;
