import React from 'react';
import { Modal } from 'react-bootstrap';

interface ResponseModalProps {
  show: boolean;
  onClose: () => void;
  success: boolean;
  message: string;
}

const ResponseModal: React.FC<ResponseModalProps> = ({
  show,
  onClose,
  success,
  message,
}) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{success ? 'Sucesso' : 'Erro'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {success ? '✅' : '❌'} {message}
      </Modal.Body>
    </Modal>
  );
};

export default ResponseModal;
