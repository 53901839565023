import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

type TimeUpMessageProps = {
  answeredCount: string;
};

const MessageContainer = styled.div`
  max-width: 500px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
  background-color: #f8f9fa;
  color: #dc3545;
  font-weight: bold;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 200px;
`;

const Icon = styled.span`
  font-size: 1.5rem;
  margin-right: 10px;
`;

const ReturnButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const TimeUpMessage: React.FC<TimeUpMessageProps> = ({ answeredCount }) => {
  const navigate = useNavigate();
  const handleReturnToExamHome = () => {
    navigate('/exam-home');
  };

  return (
    <MessageContainer>
      <p>
        <Icon>❌</Icon>O tempo da prova encerrou. Consideraremos as respostas
        submetidas.
      </p>
      <p>Progresso: {answeredCount}</p>
      <ReturnButton onClick={handleReturnToExamHome}>
        Voltar para a tela inicial
      </ReturnButton>
    </MessageContainer>
  );
};

export default TimeUpMessage;
