import {
  faExclamationCircle,
  faPlus,
  faSave,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Container, FormControl, FormSelect } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import ResponseModal from '../../../../components/ResponseModal';
import { IDegree } from '../../../../models/application-process.model';
import DisclaimerNote from '../DisclaimerNote';
import {
  ErrorMessage,
  StyledCardScreen,
  StyledForm,
  StyledInputGroup,
} from '../FormsStyles';
import DegreeList from './DegreeList';
import TitleInstructions from '../TitleInstructions';
import { DegreeInstrutions, degreeOptions } from '../../InstructionsList';

const maxItens = 2;

interface FormErrors {
  name?: string;
  workload?: string;
}

const DegreeForm: React.FC<{
  degree: boolean;
  setDegrees: (Degrees: IDegree[]) => void;
  Degrees: IDegree[];
}> = ({ setDegrees, degree, Degrees }) => {
  const [editingDegree, setEditingDegree] = useState<IDegree | null>(null);

  const [errors, setErrors] = useState<FormErrors>({});
  const [name, setModalidade] = useState('');
  const [degreeLevelsId, setDegreeLevelsId] = useState(
    'jry9h5vua0el9dzlnn4fy7j6',
  );
  const [workload, setWorkload] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    success: false,
    message: '',
  });
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const validateForm = () => {
    const newErrors: FormErrors = {};

    if (!name) {
      newErrors.name = 'Curso é obrigatório.';
    }
    if (!workload) {
      newErrors.workload = 'Carga Horária é obrigatório.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddOrSaveDegree = () => {
    if (Degrees.length >= maxItens && !editingDegree) {
      setModalContent({
        success: false,
        message: `Permitido somente ${maxItens} itens!`,
      });
      setShowModal(true);
      return;
    }

    if (!validateForm()) {
      return;
    }

    if (editingDegree) {
      const updatedDegrees = Degrees.map((exp) =>
        exp.id === editingDegree.id
          ? { ...exp, name, workload: workload, degreeLevelsId: degreeLevelsId }
          : exp,
      );
      setDegrees(updatedDegrees);
      setEditingDegree(null);
    } else {
      const newDegree = {
        id: uuidv4(),
        name: name,
        workload: workload,
        degreeLevelsId: degreeLevelsId,
      };
      setDegrees([...Degrees, newDegree]);
    }

    setModalidade('');
    setWorkload('');
    setDegreeLevelsId('jry9h5vua0el9dzlnn4fy7j6');
  };

  const handleEditDegree = (Degree: IDegree) => {
    setEditingDegree(Degree);
    setModalidade(Degree.name);
    setWorkload(Degree.workload);
    setDegreeLevelsId(Degree.degreeLevelsId);
  };

  const handleRemoveDegree = (id: string) => {
    setDegrees(Degrees.filter((exp) => exp.id !== id));
  };

  return (
    <StyledCardScreen>
      <Container>
        <TitleInstructions info={DegreeInstrutions(degree)} />
        {errors && (
          <>
            {errors.name && (
              <ErrorMessage>
                <FontAwesomeIcon icon={faExclamationCircle} /> {errors.name}
              </ErrorMessage>
            )}
            {errors.workload && (
              <ErrorMessage>
                <FontAwesomeIcon icon={faExclamationCircle} /> {errors.workload}
              </ErrorMessage>
            )}
          </>
        )}
        <StyledForm>
          <StyledInputGroup className="mb-3">
            <FormControl
              type="text"
              placeholder="Curso"
              value={name}
              onChange={(e) => setModalidade(e.target.value)}
            />
          </StyledInputGroup>
          {degreeOptions ? (
            <StyledInputGroup className="mb-3">
              <FormSelect
                value={degreeLevelsId}
                onChange={(e) => setDegreeLevelsId(e.target.value)}
              >
                {degreeOptions?.map((el) => (
                  <option id={el.id} value={el.id}>
                    {el.name}
                  </option>
                ))}
              </FormSelect>
            </StyledInputGroup>
          ) : undefined}

          <StyledInputGroup className="mb-3">
            <FormControl
              type="number"
              placeholder="Carga Horária"
              value={workload}
              onChange={(e) => setWorkload(e.target.value)}
            />
          </StyledInputGroup>
        </StyledForm>
        <Button
          variant="outline-primary"
          size="sm"
          onClick={handleAddOrSaveDegree}
        >
          <FontAwesomeIcon icon={editingDegree ? faSave : faPlus} />
          {editingDegree ? ' Salvar' : ' Adicionar'}
        </Button>
        {Degrees && Degrees.length > 0 && (
          <DegreeList
            Degrees={Degrees}
            onEdit={handleEditDegree}
            onRemove={handleRemoveDegree}
          />
        )}
        <DisclaimerNote />
        <ResponseModal
          show={showModal}
          onClose={handleCloseModal}
          success={modalContent.success}
          message={modalContent.message}
        />
      </Container>
    </StyledCardScreen>
  );
};

export default DegreeForm;
