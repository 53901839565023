import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png';
import { centeredItem, marginTop } from '../../styles/custom';
import { httpAxios } from '../../utils/axios';
import { formatDateJs } from '../../utils/format';
import { LogoXL } from '../Login/LoginStyles';
import { ApplicationProcesses } from './InterfacesProcess';
import {
  LinkButton,
  ProcessPageContainer,
  StatusButton,
  TableCard,
  TableHeader,
  Title,
} from './ProcessStyled';

const ProcessPage = () => {
  const navigate = useNavigate();
  const [processData, setApplicationProcesses] = useState<
    ApplicationProcesses[]
  >([]);

  const getIsUserSubscribe = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken) {
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };
        const { data } = await httpAxios<unknown, any>(
          //`application-processes/candidates/self/${processesData.applicationProcessesId}`,
          `application-processes/candidates/self`,
          'GET',
          headers,
        );
        navigate('/myprocesses');
      } else {
        console.error('Usuario não logado');
      }
    } catch (error) {
      console.error('Erro ao buscar processos de aplicação:', error);
    }
  };

  const fetchApplicationProcesses = async () => {
    try {
      const { data } = await httpAxios<unknown, ApplicationProcesses[]>(
        'application-processes',
        'GET',
      );
      setApplicationProcesses(data);
      getIsUserSubscribe();
    } catch (error) {
      console.error('Erro ao buscar processos de aplicação:', error);
    }
  };

  useEffect(() => {
    fetchApplicationProcesses();
  }, []);

  const handleVacanciesClick = (process: ApplicationProcesses) => {
    navigate('/vacancies', { state: { applicationProcesses: process } });
  };

  return (
    <ProcessPageContainer>
      <div style={centeredItem}>
        <LogoXL src={logo} alt="FUNEV" />
      </div>

      <Title style={marginTop}>Lista de Editais</Title>
      <TableCard>
        <TableCard.Body>
          <Table striped bordered hover>
            <TableHeader>
              <tr>
                <th>#</th>
                <th>Processo</th>
                <th>Unidade</th>
                <th>Status</th>
                <th>Data</th>
                <th>Ações</th>
              </tr>
            </TableHeader>
            <tbody>
              {processData.map((process, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{process.code}</td>
                  <td>{process.company.acronym}</td>
                  <td>
                    <StatusButton buttonType={process.status || 'Encerrado'}>
                      {process.status}
                    </StatusButton>
                  </td>
                  <td>
                    {formatDateJs(
                      process.applicationProcessesLevels[0].initDate,
                    )}{' '}
                    à{' '}
                    {formatDateJs(
                      process.applicationProcessesLevels[0].endDate,
                    )}
                  </td>

                  <td>
                    <LinkButton
                      href={process.announcement}
                      target="_blank"
                      rel="noopener noreferrer"
                      buttonType="edital"
                    >
                      Edital
                    </LinkButton>
                    <LinkButton
                      onClick={() => handleVacanciesClick(process)}
                      buttonType="vagas"
                    >
                      Vagas
                    </LinkButton>
                    <LinkButton
                      href={process.result}
                      target="_blank"
                      rel="noopener noreferrer"
                      buttonType="resultado"
                      hidden={!process.endDate || !process.result}
                    >
                      Resultado
                    </LinkButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableCard.Body>
      </TableCard>
    </ProcessPageContainer>
  );
};

export default ProcessPage;
