import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { styled } from 'styled-components';

interface InstructionsComponentProps {
  onStart: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

const Instruction = styled.p`
  margin: 10px 0;
  font-size: 16px;
  line-height: 1.5;
  &:before {
    content: '• ';
    font-size: 20px;
    color: #555;
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin: 20px 0;
  cursor: pointer;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`;

const InstructionsComponent: React.FC<InstructionsComponentProps> = ({
  onStart,
}) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  return (
    <Container>
      <Instruction>
        🔋 Certifique-se de que seu dispositivo está carregado.
      </Instruction>
      <Instruction>
        🚫 Não atualize ou saia da página, pois as respostas serão reiniciadas.
      </Instruction>
      <Instruction>
        🕗 Uma vez iniciada a prova o tempo não pode ser interrompido.
      </Instruction>
      <Instruction>
        ⌛ A duração da prova é de 60 minutos. Apenas as respostas submetidas
        serão consideradas.
      </Instruction>
      <Instruction>
        ☑️ Você pode alterar a sua resposta quantas vezes precisar, dentro do
        tempo da prova.
      </Instruction>

      <CheckboxLabel>
        <Checkbox
          type="checkbox"
          checked={isCheckboxChecked}
          onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
        />
        Eu entendi e concordo com as regras.
      </CheckboxLabel>

      <ButtonContainer>
        <Button
          onClick={() => isCheckboxChecked && onStart()}
          variant="success"
          size="lg"
          style={{ marginRight: '5px', marginBottom: '5px' }}
          disabled={!isCheckboxChecked}
        >
          <FontAwesomeIcon icon={faFile} /> Iniciar
        </Button>
      </ButtonContainer>
    </Container>
  );
};
export default InstructionsComponent;
