import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png';
import { IApplicationAndProcessCandidate } from '../../models/application-process.model.js';
import { centeredItem, marginTop } from '../../styles/custom';
import { httpAxios } from '../../utils/axios';
import { formatDateJs } from '../../utils/format';
import { LogoXL } from '../Login/LoginStyles';
import {
  ApplicationProcesses,
  ApplicationProcessesUser,
} from './InterfacesProcess';
import {
  LinkButton,
  ProcessPageContainer,
  StatusButton,
  TableCard,
  TableHeader,
  Title,
} from './ProcessStyled';

const ProcessUserPage = () => {
  const navigate = useNavigate();
  const [processData, setApplicationProcesses] = useState<
    ApplicationProcessesUser[]
  >([]);
  const [isShowButtonAnexo, setIShowButtonAnexo] = useState<boolean>(false);
  const [levelCandidate, setLevelCandidate] = useState<number>(0);

  const getIsUserSubscribe = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken) {
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };
        const { data } = await httpAxios<unknown, any>(
          `application-processes/candidates/self`,
          'GET',
          headers,
        );
        setApplicationProcesses(data);
      } else {
        console.error('Usuario não logado');
        setLevelCandidate(0);
      }
    } catch (error) {
      console.error('Erro ao buscar processos de aplicação:', error);
    }
  };

  const fetchApplicationProcesses = async () => {
    try {
      getIsUserSubscribe();
    } catch (error) {
      console.error('Erro ao buscar processos de aplicação:', error);
    }
  };

  useEffect(() => {
    fetchApplicationProcesses();
  }, []);

  const handleVacanciesClick = (process: ApplicationProcesses) => {
    navigate('/vacancies', { state: { applicationProcesses: process } });
  };

  const handleOpenAnexo = () => {
    navigate('/attach-docs-resource');
  };

  const handleOpenExam = () => {
    navigate('/exam-home');
  };

  const isApplication = (level: number) => level === 1;
  const isUpload = (level: number) => level === 2;
  const isTest = (level: number) => level === 3;

  const handleOpenProcessClick = async (
    condidateProcesses: ApplicationProcessesUser,
  ) => {
    const { data } = await httpAxios<
      undefined,
      IApplicationAndProcessCandidate
    >(`application-processes/candidates/self/${condidateProcesses.id}`, 'GET');
    const isAproved = data.applicationProcessesCandidates.status;

    window.localStorage.setItem('applicationProcessesId', data.id);

    if (!isAproved) {
      navigate('/candidates-disapproved');
      return;
    }

    const level = data.applicationProcessesCandidates.level;

    if (isApplication(level)) {
      navigate('/mysubscribe', {
        state: { data },
      });
    } else if (isUpload(level)) {
      if (data.applicationProcessesCandidates.resend) {
        navigate('/attach-docs-resource');
        return;
      }

      if (data.applicationProcessesCandidates.archiveIdIdentity) {
        navigate('/docs-uploaded');
        return;
      }

      if (data.applicationProcessesCandidates.resend) {
        navigate('/attach-docs-resource');
      } else {
        navigate('/mysubscribe', {
          state: { data },
        });
      }
    } else if (isTest(level)) {
      navigate('/exam-home');
    }
  };

  return (
    <ProcessPageContainer>
      <div style={centeredItem}>
        <LogoXL src={logo} alt="FUNEV" />
      </div>

      <>
        {levelCandidate === 2 && isShowButtonAnexo && (
          <Button
            onClick={() => handleOpenAnexo()}
            size="lg"
            style={{ marginRight: '5px', marginBottom: '5px' }}
          >
            <FontAwesomeIcon icon={faFile} /> Anexo de Documento
          </Button>
        )}
      </>

      <>
        {levelCandidate === 3 && (
          <Button
            onClick={() => handleOpenExam()}
            size="lg"
            style={{ marginRight: '5px', marginBottom: '5px' }}
          >
            <FontAwesomeIcon icon={faFile} /> Realizar Avaliação
          </Button>
        )}
      </>

      <Title style={marginTop}>Lista de Editais</Title>
      <TableCard>
        <TableCard.Body>
          <Table striped bordered hover>
            <TableHeader>
              <tr>
                <th>#</th>
                <th>Processo</th>
                <th>Unidade</th>
                <th>Status</th>
                <th>Data</th>
                <th>Ações</th>
              </tr>
            </TableHeader>
            <tbody>
              {processData.map((process, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{process.code}</td>
                  <td>{process.company.acronym}</td>
                  <td>
                    <StatusButton buttonType={process.status}>
                      {process.status}
                    </StatusButton>
                  </td>
                  <td>
                    {formatDateJs(
                      process.applicationProcessesLevels[0].initDate,
                    )}{' '}
                    à{' '}
                    {formatDateJs(
                      process.applicationProcessesLevels[0].endDate,
                    )}
                  </td>

                  <td>
                    <LinkButton
                      href={process.announcement}
                      target="_blank"
                      rel="noopener noreferrer"
                      buttonType="edital"
                    >
                      Edital
                    </LinkButton>
                    {process.status.toLocaleUpperCase() !== 'ENCERRADO' ? (
                      process.subscribed ? (
                        <LinkButton
                          onClick={() => handleOpenProcessClick(process)}
                          buttonType="open"
                        >
                          Visualizar
                        </LinkButton>
                      ) : (
                        <LinkButton
                          onClick={() => handleVacanciesClick(process)}
                          buttonType="vagas"
                        >
                          Vagas
                        </LinkButton>
                      )
                    ) : (
                      ''
                    )}
                    <LinkButton
                      href={process.result}
                      target="_blank"
                      rel="noopener noreferrer"
                      buttonType="resultado"
                      hidden={!process.endDate || !process.result}
                    >
                      Resultado
                    </LinkButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableCard.Body>
      </TableCard>
    </ProcessPageContainer>
  );
};

export default ProcessUserPage;
